import React, { useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  lockZoom,
  setCompareMode,
  setLanguageLock,
  unlockZoom,
} from '../../../../store/ui/ui.slice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { uiSelectors } from '../../../../store/ui/ui.selectors';
import { CheckboxWithLabel } from '../../../StaticComponents/CheckboxWithLabel';

import './CompareOptionsBar.scss';

import { COMPARE_MODE, CompareOptionsBarProps } from './CompareOptionsBar.types';
import { useScrollSync } from '../../../Documents/DocumentViewModal/ScrollSyncContext';

export const CompareOptionsBar: React.FC<CompareOptionsBarProps> = ({
  disabled = false,
  isDocumentMode = false,
}) => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(uiSelectors.selectCompareMode);
  const isLanguageLocked = useAppSelector(uiSelectors.selectLockLanguage);
  const zoomLocked = useAppSelector(uiSelectors.selectZoomLocked);

  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCompareMode((event.target as HTMLInputElement).value as COMPARE_MODE));
  };

  const handleLanguageLockChange = (checked: boolean) => {
    dispatch(setLanguageLock(checked));
  };

  const { isScrollSyncEnabled, setIsScrollSyncEnabled } = useScrollSync();

  const handleZoomLockChange = (checked: boolean) => {
    dispatch(checked ? lockZoom(isDocumentMode) : unlockZoom());
  };

  useEffect(() => {
    if (disabled) {
      dispatch(setCompareMode(COMPARE_MODE.SHOW_ORIGINAL));
      dispatch(setLanguageLock(false));
      dispatch(unlockZoom());
      setIsScrollSyncEnabled(false);
    }
  }, [disabled, dispatch, setIsScrollSyncEnabled]);

  return (
    <div className='compare-options-bar'>
      <RadioGroup
        row
        aria-labelledby='compare-options-label'
        name='compare-options-group'
        onChange={handleModeChange}
        value={mode}
      >
        <FormControlLabel
          disabled={disabled}
          value={COMPARE_MODE.SHOW_ORIGINAL}
          control={<Radio />}
          label='Show original'
        />
        <FormControlLabel
          disabled={disabled}
          value={COMPARE_MODE.SHOW_DIFFERENCES}
          control={<Radio />}
          label='Show differences'
        />
        <FormControlLabel
          disabled={disabled}
          value={COMPARE_MODE.SHOW_SIMILARITIES}
          control={<Radio />}
          label='Show similarities'
        />
        {!isDocumentMode ? (
          <FormControlLabel
            disabled={disabled}
            value={COMPARE_MODE.SHOW_REPLACEMENTS}
            control={<Radio />}
            label='Show replacements'
          />
        ) : null}
      </RadioGroup>
      <div>
        <CheckboxWithLabel
          disabled={disabled}
          checked={isLanguageLocked}
          onChange={(_, checked) => handleLanguageLockChange(checked)}
          label='Lock tab'
        />
        <CheckboxWithLabel
          disabled={disabled}
          checked={isScrollSyncEnabled}
          onChange={(_, checked) => setIsScrollSyncEnabled(checked)}
          label='Lock scroll'
        />
        <CheckboxWithLabel
          disabled={disabled}
          checked={zoomLocked}
          onChange={(_, checked) => handleZoomLockChange(checked)}
          label='Lock zoom'
        />
      </div>
    </div>
  );
};
