import {
  DOCUMENT_CONTEXT_MENU_ITEM_NAME,
  DocumentContextMenuItemProps,
} from './DocumentContextMenu.types';
import MenuItem from '@mui/material/MenuItem';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import React from 'react';
import { useDocumentComponentsDownload } from './DocumentContextMenuExportComponents.hooks';
import { ComponentProcessingStatus } from '../../../store/files/documents/documents.list.types';
import { isDocumentResponse } from '../../../store/files/documentsAndClauses/list.helpers';

export const DocumentContextMenuExportComponents = ({
  document,
  onClose,
  disabled,
}: DocumentContextMenuItemProps) => {
  const { handleClick } = useDocumentComponentsDownload(
    isDocumentResponse(document) ? document : undefined,
    onClose
  );
  const areComponentsExtracted =
    isDocumentResponse(document) &&
    document.ComponentProcessingStatus === ComponentProcessingStatus.DONE;
  return areComponentsExtracted ? (
    <MenuItem disabled={disabled} onClick={handleClick}>
      <FileDownloadOutlinedIcon />
      {DOCUMENT_CONTEXT_MENU_ITEM_NAME.EXPORT_COMPONENTS}
    </MenuItem>
  ) : (
    <></>
  );
};
