import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import {
  DOCUMENT_CONTEXT_MENU_ITEM_NAME,
  DocumentContextMenuItemProps,
} from './DocumentContextMenu.types';
import { isDocumentResponse } from '../../../store/files/documentsAndClauses/list.helpers';
import { useGenerateComponentsMutation } from '../../../store/files/documents/documents.list.service';
import { ConfirmationAgreementModal } from '../../StaticComponents/Modals/ConfirmationModal/ConfirmationAgreementModal';
import ErrorIcon from '@mui/icons-material/Error';
import { Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ErrorMessages } from '../../../services/errors.service.types';
import { ComponentProcessingStatus } from '../../../store/files/documents/documents.list.types';
import { AIComparisonTermsAndConditions } from '../DocumentCompareModal/DocumentAIComparison/AIComparisonTermsAndConditions';

export const DocumentContextMenuSegmentation = ({
  document,
  disabled,
  onClose,
  onItemClick,
}: DocumentContextMenuItemProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const documentId = isDocumentResponse(document) ? document.DocumentId : '';
  const statusEmpty =
    isDocumentResponse(document) &&
    document.ComponentProcessingStatus === ComponentProcessingStatus.EMPTY;
  const statusError =
    isDocumentResponse(document) &&
    document.ComponentProcessingStatus === ComponentProcessingStatus.ERROR;

  const [generate] = useGenerateComponentsMutation();

  const handleMenuItemClick = () => {
    setShowModal(true);
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await generate(documentId).unwrap();
      onItemClick?.(DOCUMENT_CONTEXT_MENU_ITEM_NAME.SEGMENTATION);
      onClose();
    } catch (e) {
      enqueueSnackbar(ErrorMessages.SegmentationGenerationError, { variant: 'error' });
    }
    setLoading(false);
  };

  if (!statusEmpty && !statusError) return null;
  return (
    <>
      {statusError && (
        <Tooltip title='The segmentation process has failed. Please contact GWR support team for additional information.'>
          <ErrorIcon className='documents-list-menu__error-icon' />
        </Tooltip>
      )}

      <MenuItem disabled={disabled || statusError} onClick={handleMenuItemClick}>
        <LayersOutlinedIcon />
        Segmentation
      </MenuItem>

      <ConfirmationAgreementModal
        className='chat-agreement-modal'
        open={showModal}
        loading={loading}
        onClose={onClose}
        onConfirm={handleConfirm}
        title='Segmentation warning'
        message={AIComparisonTermsAndConditions}
        label='I hereby confirm I have read and understood the statement.'
        cancelButtonLabel='Cancel'
        successButtonLabel='Confirm'
      />
    </>
  );
};
