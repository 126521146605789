import { useCallback } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { downloadDocumentComponent } from '../../../store/files/documents/documents.slice';
import { DocumentResponse } from '../../../store/files/documents/documents.list.types';
import { UI_DATE_TIME_FILE_FORMAT } from '../../../config/config';
import { formatDate } from '../../SpreadSheet/DocumentsGrid/DocumentsGrid.helpers';
import { showLoader } from '../../../store/ui/ui.slice';
import { FILE_EXTENSIONS } from '../../../store/ui/ui.types';

export const useDocumentComponentsDownload = (
  document?: DocumentResponse,
  onClose?: () => void
) => {
  const dispatch = useAppDispatch();

  const generateFileName = (fileName: string) =>
    `${fileName.replace(FILE_EXTENSIONS.PDF, '')}_Components_${formatDate(
      new Date(),
      UI_DATE_TIME_FILE_FORMAT
    )}${FILE_EXTENSIONS.DOCX}`;

  const handleDownloadDocument = useCallback(() => {
    if (document) {
      dispatch(showLoader(true));
      dispatch(
        downloadDocumentComponent({
          fileName: generateFileName(document?.FileName),
          documentId: document?.DocumentId,
        })
      );
      onClose && onClose();
    }
  }, [dispatch, document, onClose]);

  return { handleClick: handleDownloadDocument };
};
