import React, { useEffect, useState } from 'react';
import {
  DocumentComponentContextMenuAction,
  DocumentComponentProps,
} from './DocumentComponents.types';
import { DocumentComponentSplit } from './DocumentComponentSplit';
import { DocumentComponentContextMenu } from './DocumentComponentContextMenu';
import { DocumentComponentStatus } from '../../../store/files/documents/documents.list.types';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export const DocumentComponent = ({
  component,
  onConfirm,
  onStatusChange,
}: DocumentComponentProps) => {
  const [splitMode, setSplitMode] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  // When switching from IRRELEVANT → RELEVANT: collapses the text (though visually nothing changes since relevant components show full text anyway)
  // When switching from RELEVANT → IRRELEVANT: ensures the component starts in collapsed state
  useEffect(() => {
    setIsExpanded(false);
  }, [component.Status]);

  const getCleanContent = (content: string) => content.trim();
  const isIrrelevant = component.Status === DocumentComponentStatus.IRRELEVANT;

  const handleClick = (action: DocumentComponentContextMenuAction) => {
    if (action === DocumentComponentContextMenuAction.SPLIT) {
      setSplitMode(true);
    } else if (action === DocumentComponentContextMenuAction.MARK_AS_IRRELEVANT) {
      const newStatus =
        component.Status === DocumentComponentStatus.RELEVANT
          ? DocumentComponentStatus.IRRELEVANT
          : DocumentComponentStatus.RELEVANT;
      onStatusChange(newStatus);
    }
  };

  return splitMode ? (
    <DocumentComponentSplit
      componentContent={component.Content}
      onClose={() => setSplitMode(false)}
      onConfirm={onConfirm}
    />
  ) : (
    <div
      className={`document-component ${isIrrelevant ? 'document-component--irrelevant' : ''} ${
        menuVisible ? 'document-component--menu-visible' : ''
      }`}
      onMouseEnter={() => setMenuVisible(true)}
      onMouseLeave={() => setMenuVisible(false)}
      role='none'
    >
      <DocumentComponentContextMenu onClick={handleClick} component={component} />

      <div className='document-component__text-wrapper'>
        <div
          className={`document-component__text ${
            isExpanded ? 'document-component__text--expanded' : ''
          }`}
          onDoubleClick={() => isIrrelevant && setIsExpanded(!isExpanded)}
        >
          {getCleanContent(component.Content)}
        </div>
        {isIrrelevant && !isExpanded && (
          <IconButton className='document-component__expand' onClick={() => setIsExpanded(true)}>
            <MoreHorizIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};
