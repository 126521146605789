import axios, { AxiosResponse } from 'axios';
import { getAuthorizationHeader } from '../../components/Auth/auth.helpers';
import {
  DOCUMENT_COMPONENT_COMPARISON_EXPORT_ENDPOINT,
  DOCUMENT_COMPONENT_EXPORT_ENDPOINT,
} from '../../services/api.service.config';

enum FileType {
  PDF = 'application/pdf',
  CSV = 'text/csv',
  DOCX_IN_BASE64 = 'text/plain',
}

class DownloadService {
  private forceDownload(data: Blob, fileName: string) {
    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  }

  private forceDownloadDocxInBase64(response: AxiosResponse, fileName: string) {
    const base64Content = response.data;
    const byteCharacters = atob(base64Content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    this.forceDownload(blob, fileName);
  }

  private async downloadFile(
    PreSignedUrl: string,
    fileName: string,
    fileType: FileType,
    addAuthToken?: boolean
  ) {
    let headers = {};
    if (addAuthToken) {
      const { name, value } = await getAuthorizationHeader();
      headers = {
        [name]: value,
      };
    }

    const response = await axios.get(PreSignedUrl, {
      responseType: fileType === FileType.DOCX_IN_BASE64 ? 'json' : 'blob',
      headers,
    });

    if (fileType === FileType.DOCX_IN_BASE64) {
      this.forceDownloadDocxInBase64(response, fileName);
    } else {
      this.forceDownload(new Blob([response.data], { type: fileType }), fileName);
    }
  }

  async downloadDocument(PreSignedUrl: string, fileName: string) {
    await this.downloadFile(PreSignedUrl, fileName, FileType.PDF);
  }

  async downloadReport(PreSignedUrl: string, fileName: string, addAuthToken?: boolean) {
    await this.downloadFile(PreSignedUrl, fileName, FileType.CSV, addAuthToken);
  }

  async downloadDocumentComponents(fileName: string, documentId: string) {
    await this.downloadFile(
      DOCUMENT_COMPONENT_EXPORT_ENDPOINT.replace('{id}', documentId),
      fileName,
      FileType.DOCX_IN_BASE64,
      true
    );
  }

  async downloadDocumentComponentsComparison(fileName: string, comparisonId: string) {
    await this.downloadFile(
      DOCUMENT_COMPONENT_COMPARISON_EXPORT_ENDPOINT.replace('{id}', comparisonId),
      fileName,
      FileType.DOCX_IN_BASE64,
      true
    );
  }
}

const downloadService = new DownloadService();

export default downloadService;
