import React, { useCallback, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { ModalClose } from '../../StaticComponents/Modals/ModalClose';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import './DocumentCompareModal.scss';
import { Backdrop } from '@mui/material';
import { useGetDictionariesQueryDefaults } from '../../../store/files/upload/list.service.hooks';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import { closeCompareModal } from '../../../store/ui/ui.slice';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { DraggableBarButton } from '../../DocumentBar/BarButton/DraggableBarButton';
import { moveItems, swapItems } from './DocumentCompareModal.helpers';
import { isDocumentResponse } from '../../../store/files/documentsAndClauses/list.helpers';
import { DocumentResponse } from '../../../store/files/documents/documents.list.types';
import { DroppableDocumentComparePanel } from './DocumentComparePanel/DroppableDocumentComparePanel';
import { closeMinimizedDoc } from '../../../store/ui/ui.actions';
import { CompareOptionsBar } from '../../Clauses/ClauseCompareModal/CompareOptionsBar/CompareOptionsBar';
import { Order } from '../../StaticComponents/FileViewer/useDocumentCompare';
import { ScrollSyncProvider } from '../DocumentViewModal/ScrollSyncContext';
import { AIComparisonFlow } from './DocumentAIComparison/AIComparisonFlow';
import { LanguageToggleSwitchTypes } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.types';

export const DocumentCompareModal = () => {
  const dispatch = useAppDispatch();
  const { data: dictionaries } = useGetDictionariesQueryDefaults({ AccessMode: AccessMode.READ });

  const comparisonItems = useAppSelector(uiSelectors.selectModalCompare);
  const [comparedDocs, setComparedDocs] = useState<DocumentResponse[]>([]);
  const [documentsLanguages, setDocumentsLanguages] = useState<LanguageToggleSwitchTypes[]>([]);

  useEffect(() => {
    setComparedDocs(comparisonItems?.filter(isDocumentResponse) ?? []);
    setDocumentsLanguages([]);
  }, [comparisonItems]);

  const handleClose = () => {
    dispatch(closeCompareModal());
  };

  const handleDrop = useCallback(
    (from: number, to: number) => {
      setComparedDocs(swapItems(comparedDocs, from, to));
    },
    [comparedDocs]
  );

  const handleSwitchPrevNext = useCallback(
    (dir: 1 | -1 = 1) => {
      setComparedDocs(moveItems(comparedDocs, dir));
    },
    [comparedDocs]
  );

  const handleRemove = (dataId: string) => (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    dispatch(closeMinimizedDoc([dataId]));
    setComparedDocs(comparedDocs.filter((doc) => doc.DocumentId !== dataId));
  };

  const threeClassName = comparedDocs.length > 2 ? 'document-compare-three' : '';
  const multipleClassName = comparedDocs.length > 3 ? 'document-compare-multiple' : '';
  const draggableEnabled = comparedDocs.length > 2;

  const handleLanguageChange = (index: number, language: LanguageToggleSwitchTypes) => {
    const newLanguages = [...documentsLanguages];
    newLanguages[index] = language;
    setDocumentsLanguages(newLanguages);
  };

  const isComponentsViewActive = documentsLanguages.some(
    (lang) => lang === LanguageToggleSwitchTypes.Components
  );

  if (!dictionaries || !comparisonItems?.length) {
    return null;
  }

  return (
    <ScrollSyncProvider>
      <Modal
        open={!!comparedDocs.length}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 0,
        }}
        classes={{
          root: 'compare-modal-root',
        }}
      >
        <div className='metadata-edit-modal compare-modal'>
          <Grid
            container
            className={`document-compare-wrap ${threeClassName} ${multipleClassName}`}
          >
            {comparedDocs.slice(0, 3).map((item, i) => {
              return (
                <DroppableDocumentComparePanel
                  documentDictionaries={dictionaries}
                  document={item}
                  key={item.DocumentId}
                  index={i as Order}
                  onDrop={handleDrop}
                  onLanguageChange={(lang) => handleLanguageChange(i, lang)}
                />
              );
            })}
          </Grid>

          <ModalClose onClose={handleClose} />

          {multipleClassName ? (
            <div className='document-compare-nav'>
              <ArrowBack
                className='document-compare-nav_prev'
                onClick={() => handleSwitchPrevNext()}
              />
              <ArrowForward
                className='document-compare-nav_next'
                onClick={() => handleSwitchPrevNext(-1)}
              />
            </div>
          ) : null}

          {draggableEnabled ? (
            <div className='document-compare-bar'>
              {comparedDocs.map(({ DocumentId, FileName }, i) => (
                <DraggableBarButton
                  id={DocumentId}
                  index={i}
                  handleClose={handleRemove(DocumentId)}
                  name={FileName}
                  key={DocumentId}
                />
              ))}
            </div>
          ) : null}
          <CompareOptionsBar isDocumentMode disabled={isComponentsViewActive} />
          <AIComparisonFlow comparedDocs={comparedDocs} />
        </div>
      </Modal>
    </ScrollSyncProvider>
  );
};
