import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';

export const AIComparisonTermsAndConditions = (
  <div className='chat-agreement-modal__terms_and_cond'>
    <p>
      <span>
        <WarningIcon className='warning-icon' />
        To use the functionality, you must accept the terms and conditions.
      </span>
    </p>
    <span>1. As a user, you are responsible for the output: Check the results carefully.</span>
    <span>2. GWR AI Assistant can give wrong or imaginary results. </span>
    <span>
      3. Please note that the use of the GWR AI Assistant is associated with additional costs.
      Therefore, we kindly ask you to use this functionality responsibly.
    </span>
  </div>
);
