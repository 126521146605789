import React, { useCallback, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { DocumentComponentSplitProps } from './DocumentComponents.types';
import { HandleSelector } from './HandleSelector';

export const DocumentComponentSplit = ({
  componentContent,
  onClose,
  onConfirm,
}: DocumentComponentSplitProps) => {
  const [selection, setSelection] = useState<number[] | undefined>();

  const handleSelect = useCallback((offsets?: number[]) => {
    setSelection(offsets);
  }, []);

  const handleConfirm = () => {
    selection && onConfirm(selection);
  };

  return (
    <div className='document-component'>
      <HandleSelector componentContent={componentContent} onSelect={handleSelect} />

      <div className='document-component__buttons'>
        <LoadingButton variant='outlined' startIcon={<CloseIcon />} disableRipple onClick={onClose}>
          Cancel
        </LoadingButton>

        <LoadingButton
          variant='contained'
          startIcon={<CheckIcon />}
          disableRipple
          onClick={handleConfirm}
          disabled={!selection}
        >
          Apply division
        </LoadingButton>
      </div>
    </div>
  );
};
