import { DocumentResponse } from '../../store/files/documents/documents.list.types';
import { ClauseResponse, ClauseStatuses } from '../../store/files/clauses/clauses.list.types';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';

export enum ClauseMetadataFields {
  ClauseName = 'ClauseName',
  Entities = 'Entities',
  Lobs = 'Lobs',
  Lops = 'Lops',
  Countries = 'Countries',
  Classification = 'Classification',
  BusinessTypes = 'BusinessTypes',
  Language = 'Language',
  ClauseSources = 'ClauseSources',
  ClauseTypes = 'ClauseTypes',
  IndividualizationReason = 'IndividualizationReason',
  ClauseNumber = 'ClauseNumber',
  Tag = 'Tag',
  Content = 'Content',
  ClauseStatus = 'ClauseStatus',
  Remarks = 'Remarks',
  IsDraft = 'IsDraft',
  Approved = 'Approved',
  ApprovalComment = 'ApprovalComment',
}

export type ClauseMetadata = {
  [ClauseMetadataFields.ClauseName]: string;
  [ClauseMetadataFields.Entities]: string[];
  [ClauseMetadataFields.Lobs]: string[];
  [ClauseMetadataFields.Lops]: string[];
  [ClauseMetadataFields.Countries]: string[];
  [ClauseMetadataFields.Classification]: string;
  [ClauseMetadataFields.BusinessTypes]: string[];
  [ClauseMetadataFields.Language]: string;
  [ClauseMetadataFields.ClauseSources]: string[];
  [ClauseMetadataFields.ClauseTypes]: string[];
  [ClauseMetadataFields.IndividualizationReason]: string;
  [ClauseMetadataFields.ClauseNumber]: string;
  [ClauseMetadataFields.Content]: string;
  [ClauseMetadataFields.Tag]: string;
  [ClauseMetadataFields.ClauseStatus]: ClauseStatuses;
  [ClauseMetadataFields.Remarks]: string;
  [ClauseMetadataFields.IsDraft]: boolean;
  [ClauseMetadataFields.Approved]: string;
  [ClauseMetadataFields.ApprovalComment]: string;
};

export interface UploadClauseLocationState {
  document?: DocumentResponse;
  clause?: ClauseResponse;
}

export interface FieldProps {
  field: ControllerRenderProps<ClauseMetadata, ClauseMetadataFields.Content>;
  fieldState: ControllerFieldState;
}

export const CLAUSE_TYPE_PRIMARY = 'Primary';
export const CLASSIFICATION_C2 = 'C2';
