import React, { useEffect, useRef, useState } from 'react';
import { HandleSelectorProps } from './DocumentComponents.types';
import './HandleSelector.scss';

const HANDLE_START_CLASS = 'handle-selector__handle--start';
const HANDLE_END_CLASS = 'handle-selector__handle--end';
const CHARACTER_CLASS = 'handle-selector__char';
const DATA_INDEX = 'data-index';

export const getSelection = (selectionStart: number, selectionEnd: number, length: number) =>
  selectionStart !== selectionEnd && !(selectionStart === 0 && selectionEnd === length)
    ? [selectionStart, selectionEnd]
    : undefined;

const getSelectedClass = (selectionStart: number, selectionEnd: number, index: number) =>
  selectionStart <= index && index < selectionEnd ? CHARACTER_CLASS + '--selected' : '';

export const HandleSelector = ({ componentContent, onSelect }: HandleSelectorProps) => {
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(componentContent.length);

  const isDraggingRef = useRef<boolean>(false);
  const draggingHandleRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    const handleMouseUp = () => {
      isDraggingRef.current = false;
      draggingHandleRef.current?.classList.remove('handle-selector__handle--is-dragging');
    };
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleMouseDown: React.ReactEventHandler<HTMLSpanElement> = (event) => {
    isDraggingRef.current = true;
    draggingHandleRef.current = event.currentTarget;
    draggingHandleRef.current.classList.add('handle-selector__handle--is-dragging');
  };

  const handleMouseOver: React.ReactEventHandler<HTMLSpanElement> = ({ currentTarget }) => {
    if (!draggingHandleRef.current || !isDraggingRef.current) return;

    const dataIndex = currentTarget.getAttribute(DATA_INDEX);
    const charIndex = dataIndex ? parseInt(dataIndex) : undefined;

    if (!currentTarget.classList.contains(CHARACTER_CLASS) || charIndex === undefined) return;

    if (draggingHandleRef.current.classList.contains(HANDLE_START_CLASS)) {
      if (charIndex > selectionEnd) return;
      draggingHandleRef.current.remove();

      currentTarget.parentNode?.insertBefore(draggingHandleRef.current, currentTarget);
      setSelectionStart(charIndex);
      onSelect(getSelection(charIndex, selectionEnd, componentContent.length));
    } else if (draggingHandleRef.current.classList.contains(HANDLE_END_CLASS)) {
      if (charIndex < selectionStart) return;
      draggingHandleRef.current.remove();

      currentTarget.parentNode?.insertBefore(draggingHandleRef.current, currentTarget.nextSibling);
      setSelectionEnd(charIndex + 1);
      onSelect(getSelection(selectionStart, charIndex + 1, componentContent.length));
    }
  };

  return (
    <div className='handle-selector document-component__text'>
      <span
        className={
          'handle-selector__handle ' +
          HANDLE_START_CLASS +
          (selectionEnd < componentContent.length ? ' handle-selector__handle--disabled' : '')
        }
        onMouseDown={handleMouseDown}
        role='none'
      />

      {componentContent.split('').map((char, i) => (
        <span
          key={i + char}
          {...{ [DATA_INDEX]: i }}
          className={`${CHARACTER_CLASS} ${getSelectedClass(selectionStart, selectionEnd, i)}`}
          onMouseOver={handleMouseOver}
          role='none'
        >
          {char}
        </span>
      ))}

      <span
        className={
          'handle-selector__handle ' +
          HANDLE_END_CLASS +
          (selectionStart > 0 ? ' handle-selector__handle--disabled' : '')
        }
        onMouseDown={handleMouseDown}
        role='none'
      />
    </div>
  );
};
