import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { CircularProgress, Paper } from '@mui/material';
import { BasePopoverButtonProps } from './BasePopoverButton.types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const BasePopoverButton = ({ children, loading, disabled }: BasePopoverButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <>
      <IconButton aria-describedby={id} size='small' onClick={handleClick} disabled={disabled}>
        {loading ? <CircularProgress size={10} /> : <KeyboardArrowDownIcon fontSize='inherit' />}
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClick}
      >
        <Paper sx={{ p: '24px 16px', maxWidth: 332 }}>{children}</Paper>
      </Popover>
    </>
  );
};
