import { ListParams } from '../documentsAndClauses/list.types';
import { DocumentStatus, QuerySynonyms } from '../documents/documents.list.types';

export enum ClauseStatuses {
  INVALID = 'Invalid',
  BEST_PRACTICE = 'Best practice',
  OTHER = 'Other',
}

export enum ClauseTags {
  OTHER = 'Other',
}

export interface ClauseResponse {
  BusinessTypes: string[];
  Classification: string;
  ClauseId: string;
  ClauseName: string;
  ClauseNumber: string;
  ClauseSources: string[];
  ClauseTypes: string[];
  Countries: string[];
  Entities: string[];
  IndividualizationReason: string;
  Language: string;
  Lobs: string[];
  Lops: string[];
  Provider: string;
  Reported: boolean;
  ReportedAt: string;
  ReportedBy: string;
  ReportedComment: string;
  ReportedReason: string;
  Status: DocumentStatus;
  EditedBy: string;
  EditedAt: string;
  UploadedBy: string;
  UploadedAt: string;
  Text: string;
  Tag: string;
  ClauseStatus: ClauseStatuses;
  Remarks: string;
  IsDraft: boolean;
  LegalApproved: boolean | '';
  ComplianceApproved: boolean | '';
  LegalApprovedComment: string;
  ComplianceApprovedComment: string;
}

export type Clause = ClauseResponse;

export enum CLAUSES_LIST_API_TAGS {
  CLAUSES_LIST = 'ClausesList',
  CLAUSE_FILE = 'ClauseFile',
}

export enum CLAUSES_LIST_API_ENDPOINTS {
  GET_CLAUSE_FILE = 'getClauseFile',
  GET_CLAUSES_LIST = 'getClausesList',
  DELETE_CLAUSE = 'deleteClause',
  UPDATE_CLAUSES_METADATA = 'updateClausesMetadata',
  CREATE_CLAUSE = 'createClause',
  RECREATE_CLAUSE = 'recreateClause',
  REPORT_CLAUSE = 'reportClause',
  APPROVE_CLAUSE = 'approveClause',
  DELETE_CLAUSE_REPORT = 'deleteClauseReport',
  GET_INHERITED_METADATA = 'getInheritedMetadata',
  GET_CLAUSE_TAGS = 'getClauseTags',
}

export type ClausesListParamsNames = keyof ClausesListParams;

export interface ClausesListParams extends ListParams {
  BookmarkId?: string;
  BusinessTypes?: string[];
  Classifications?: string[];
  ClauseId?: string[];
  ClauseTypes?: string[];
  ClauseSources?: string[];
  Countries?: string[];
  Entities?: string[];
  Language?: string[];
  Lobs?: string[];
  Lops?: string[];
  Status?: string[];
  Provider?: string[];
  Tag?: string[];
  ClauseStatus?: string[];
  ApprovalStatus?: string;
}

export interface ClausesListResponse {
  Page: number;
  TotalPages: number;
  TotalClauseCount: number;
  Clauses: ClauseResponse[];
  QueriesSynonyms: QuerySynonyms[];
}

export type ClauseMetadataUpdateRequest = {
  ClauseName?: string;
  Lobs?: string[];
  Lops?: string[];
  BusinessTypes?: string[];
  ClauseTypes?: string[];
  Classification?: string;
  ClauseSources?: string[];
  IndividualizationReason?: string;
  Entities?: string[];
  Countries?: string[];
  Tag?: string;
  ClauseStatus?: string;
  Remarks?: string;
  Content?: string;
};

export interface ClauseMetadataUpdateResponseError {
  clause_id?: string | null;
  error?: string;
  errorMessage?: string;
}

export interface ClauseMetadataUpdateResponse {
  Succeeded?: string[];
  Errors?: ClauseMetadataUpdateResponseError[];
}

export interface CreateClauseRequest {
  Lobs: string[];
  Lops: string[];
  Classification: string;
  Language: string;
  Countries: string[];
  Content: string;
  BusinessTypes: string[];
  ClauseName: string;
  Entities: string[];
  ExtractedFrom?: string;
}

export interface RecreateClauseRequest {
  ClauseId: string;
  ClauseName: string;
  ClauseNumber: string;
  ClauseSources: string[];
  ClauseStatus: string;
  ClauseTypes: string[];
  Content: string;
  IndividualizationReason: string;
  Remarks: string;
}

export interface RecreateClauseResponse {
  ClauseId: string;
}

export enum ClauseFileResponseFields {
  OriginalTxtUrl = 'OriginalTxtUrl',
  TranslatedTxtUrl = 'TranslatedTxtUrl',
}

export type ClauseFileResponse = {
  [value in ClauseFileResponseFields]: string;
};

export interface ClauseInheritedMetadataResponse
  extends Pick<
    ClauseResponse,
    'ClauseId' | 'Entities' | 'Lobs' | 'Lops' | 'BusinessTypes' | 'Countries'
  > {}

export type Tags = {
  value: string;
  label: string;
}[];

export interface ClauseApprovalRequest {
  Approved: string;
  ApprovalComment?: string;
  ClauseStatus?: string;
  Remarks?: string;
}

export interface ClauseApprovalResponse {
  Errors: Record<string, string>[];
  Succeeded: string[];
}
