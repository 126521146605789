import { v4 as uuidv4 } from 'uuid';
import {
  DocumentComponentAdjustActionType,
  DocumentComponentsAdjustRequest,
  DocumentComponentStatus,
  DocumentComponentType,
  DOCUMENTS_LIST_API_ENDPOINTS,
} from '../../../store/files/documents/documents.list.types';
import { documentsListApi } from '../../../store/files/documents/documents.list.service';

const getNewComponent = (Content: string) => ({
  Id: uuidv4(),
  Content,
  Status: DocumentComponentStatus.RELEVANT,
});

const getPreviousComponentId = (components: DocumentComponentType[], componentIndex: number) =>
  componentIndex === 0 ? '' : components[componentIndex - 1].Id;

export const getMergedComponents = (
  components: DocumentComponentType[],
  id: DocumentComponentType['Id']
) => {
  const component = components.find(({ Id }) => Id === id);
  const componentIndex = components.findIndex(({ Id }) => Id === id);
  const nextComponent = components[componentIndex + 1];

  if (!component || !nextComponent) throw new Error('Component not found');

  const separator = component.Content.endsWith('\n') ? '' : '\n';
  const newComponent: DocumentComponentType = getNewComponent(
    component.Content + separator + nextComponent.Content
  );

  const adjustActions: DocumentComponentsAdjustRequest['body']['Actions'] = [
    {
      ActionType: DocumentComponentAdjustActionType.ADD,
      ComponentId: newComponent.Id,
      Content: newComponent.Content,
      Predecessor: getPreviousComponentId(components, componentIndex),
    },
    {
      ActionType: DocumentComponentAdjustActionType.DELETE,
      ComponentId: component.Id,
    },
    {
      ActionType: DocumentComponentAdjustActionType.DELETE,
      ComponentId: nextComponent.Id,
    },
  ];

  const newComponents = components
    .map((component) => (component.Id === id ? newComponent : component))
    .filter((component) => component.Id !== nextComponent.Id);

  return { adjustActions, newComponents };
};

export const getSplitComponents = (
  components: DocumentComponentType[],
  id: DocumentComponentType['Id'],
  offsets: number[]
) => {
  const component = components.find(({ Id }) => Id === id);
  const componentIndex = components.findIndex(({ Id }) => Id === id);

  if (!component) throw new Error('Component not found');

  const splitComponents = [
    component.Content.slice(0, offsets[0]),
    component.Content.slice(offsets[0], offsets[1]),
    component.Content.slice(offsets[1]),
  ]
    .filter(Boolean)
    .map<DocumentComponentType>(getNewComponent);

  const adjustActions: DocumentComponentsAdjustRequest['body']['Actions'] = [
    ...splitComponents.map((newComponent, index, array) => ({
      ActionType: DocumentComponentAdjustActionType.ADD,
      ComponentId: newComponent.Id,
      Content: newComponent.Content,
      Predecessor:
        getPreviousComponentId(array, index) || getPreviousComponentId(components, componentIndex),
    })),
    {
      ActionType: DocumentComponentAdjustActionType.DELETE,
      ComponentId: component.Id,
    },
  ];

  const newComponents = components.flatMap((component) =>
    component.Id === id ? splitComponents : [component]
  );

  return { adjustActions, newComponents };
};

export const updateDocumentComponents = (
  documentId: string,
  components: DocumentComponentType[],
  timestamp: string
) =>
  documentsListApi.util.updateQueryData(
    DOCUMENTS_LIST_API_ENDPOINTS.GET_COMPONENTS,
    documentId,
    (cachedComponentsResponse) => {
      cachedComponentsResponse.Components = components;
      cachedComponentsResponse.Timestamp = timestamp;
    }
  );

export const getStatusChangeComponent = (
  components: DocumentComponentType[],
  id: DocumentComponentType['Id'],
  newStatus: DocumentComponentStatus
) => {
  const component = components.find(({ Id }) => Id === id);
  if (!component) throw new Error('Component not found');

  const adjustActions: DocumentComponentsAdjustRequest['body']['Actions'] = [
    {
      ActionType: DocumentComponentAdjustActionType.CHANGE_STATUS,
      ComponentId: id,
      Status: newStatus,
    },
  ];

  const newComponents = components.map((component) =>
    component.Id === id ? { ...component, Status: newStatus } : component
  );

  return { adjustActions, newComponents };
};
