import React, { useEffect } from 'react';
import { AchDocumentViewerProps } from './DocumentViewer.types';
import { useGetDocumentFileQuery } from '../../../store/files/documents/documents.list.service';
import PdfViewer from '../../StaticComponents/FileViewer/PdfViewer';
import { HtmlViewer } from '../../StaticComponents/FileViewer/HtmlViewer';
import { LanguageToggleSwitchTypes } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.types';
import { DocumentFileResponseFields } from '../../../store/files/documents/documents.list.types';
import { useSearch } from '../../StaticComponents/FileViewer/ViewerToolbar/SearchContext';
import { DOCUMENT_CHAT_ZOOM_SCALES } from '../../../config/config';
import { useSimilarHighlightsHook } from './useSimilarHighlights.hook';
import { useDetermineViewerLanguage } from './AchDocumentViewer.hooks';
import { DocumentComponentsViewer } from '../../Documents/DocumentComponents/DocumentComponentsViewer';

export const AchDocumentViewer = ({
  documentId = '',
  language,
  keyword,
  method,
  order,
  searchOption,
  debug,
  clauses,
  chatMode,
  previewMode,
  componentProcessingStatus,
  synonyms,
}: AchDocumentViewerProps) => {
  const similar = useSimilarHighlightsHook(chatMode ? undefined : documentId);

  const { data, isFetching, isError } = useGetDocumentFileQuery(documentId, {
    skip: !documentId,
  });

  const viewerLanguage = useDetermineViewerLanguage(
    language,
    order,
    data?.[DocumentFileResponseFields.TranslatedHtmlUrl]
  );

  const { setSearchTerm } = useSearch();
  useEffect(() => {
    const searchTermPrefill = keyword ? keyword : '';
    setSearchTerm(searchTermPrefill);
    return () => setSearchTerm('');
  }, [keyword, setSearchTerm]);

  switch (viewerLanguage) {
    case LanguageToggleSwitchTypes.PDF: {
      const url = data?.[DocumentFileResponseFields.OriginalPdfUrl];
      return (
        <PdfViewer
          loading={isFetching}
          error={isError}
          url={url}
          keyword={keyword}
          method={method}
          searchOption={searchOption}
          clauses={clauses}
          zoomScales={chatMode ? DOCUMENT_CHAT_ZOOM_SCALES : undefined}
          centered={chatMode}
          similar={similar}
          chatMode={chatMode}
          previewMode={previewMode}
          synonyms={synonyms}
        />
      );
    }
    case LanguageToggleSwitchTypes.Original: {
      const url = data?.[DocumentFileResponseFields.OriginalHtmlUrl];
      return (
        <HtmlViewer
          loading={isFetching}
          error={isError}
          url={url}
          keyword={keyword}
          method={method}
          order={order}
          language={LanguageToggleSwitchTypes.Original}
          searchOption={searchOption}
          debug={debug}
          clauses={clauses}
          zoomScales={chatMode ? DOCUMENT_CHAT_ZOOM_SCALES : undefined}
          similar={similar}
          chatMode={chatMode}
          synonyms={synonyms}
        />
      );
    }
    case LanguageToggleSwitchTypes.English: {
      const url = data?.[DocumentFileResponseFields.TranslatedHtmlUrl];
      return (
        <HtmlViewer
          loading={isFetching}
          error={isError}
          url={url}
          keyword={keyword}
          method={method}
          order={order}
          language={LanguageToggleSwitchTypes.English}
          searchOption={searchOption}
          debug={debug}
          zoomScales={chatMode ? DOCUMENT_CHAT_ZOOM_SCALES : undefined}
          synonyms={synonyms}
        />
      );
    }
    case LanguageToggleSwitchTypes.Components: {
      return (
        <DocumentComponentsViewer
          documentId={documentId}
          componentProcessingStatus={componentProcessingStatus}
        />
      );
    }
  }
};
