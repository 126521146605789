import React from 'react';
import './ReferenceDocumentSelectionModal.scss';
import { ReferenceDocumentSelectionModalMessages } from './DocumentAIComparison.messages';
import { MarsModal } from '../../../StaticComponents/Modals/MarsModal';
import {
  ReferenceDocumentFormValues,
  ReferenceDocumentSelectionModalProps,
} from './ReferenceDocumentSelection.types';
import { Controller, useForm } from 'react-hook-form';
import { FormWarning, requiredRule } from '../../../UploadFiles/MetadataForm/MetadataFormHelpers';
import { ComponentProcessingStatus } from '../../../../store/files/documents/documents.list.types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export const ReferenceDocumentSelectionModal: React.FC<ReferenceDocumentSelectionModalProps> = ({
  open,
  comparedDocs,
  onConfirm,
  onClose,
}) => {
  const { control, getValues, trigger, reset, clearErrors } = useForm<ReferenceDocumentFormValues>({
    defaultValues: { selectedDocument: comparedDocs[0].FileName },
  });

  const getSelectedDoc = (fileName: string) =>
    comparedDocs.find((doc) => doc.FileName === fileName);

  const isDocumentReady = (fileName: string) =>
    getSelectedDoc(fileName)?.ComponentProcessingStatus === ComponentProcessingStatus.DONE;

  const handleConfirm = () => {
    const selectedFileName = getValues('selectedDocument');

    if (isDocumentReady(selectedFileName)) {
      reset();
      onConfirm(selectedFileName);
    } else {
      trigger('selectedDocument');
    }
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <MarsModal
      open={open}
      onClose={handleClose}
      buttonLabel={ReferenceDocumentSelectionModalMessages.BUTTON_LABEL}
      showCancelButton={true}
      onConfirm={handleConfirm}
      modalClassName='reference-document-modal-wrapper'
    >
      <div className='bookmarks-modal-title'>
        {ReferenceDocumentSelectionModalMessages.MODAL_TITLE}
      </div>

      <div className='reference-document-modal'>
        <div>{ReferenceDocumentSelectionModalMessages.MODAL_MESSAGE}</div>
        <div className='metadata-form'>
          <div className='metadata-form-block'>
            <Controller
              control={control}
              name='selectedDocument'
              defaultValue=''
              rules={{
                ...requiredRule(true, true),
                validate: (value) =>
                  isDocumentReady(value) ||
                  ReferenceDocumentSelectionModalMessages.VALIDATION_ERROR,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  fullWidth
                  size='small'
                  variant='filled'
                  label='Select Document'
                  helperText={<FormWarning error={error} />}
                  onChange={(e) => {
                    field.onChange(e);
                    clearErrors('selectedDocument');
                  }}
                >
                  {comparedDocs.map((comparedDoc) => (
                    <MenuItem key={comparedDoc.FileName} value={comparedDoc.FileName}>
                      {comparedDoc.FileName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </div>
        </div>
      </div>
    </MarsModal>
  );
};
