import {
  LanguageToggleSwitchHookReturn,
  LanguageToggleSwitchTypes,
} from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.types';
import {
  DOCUMENT_CONTEXT_MENU_ITEM_NAME,
  DocumentContextMenuItemClick,
} from '../DocumentContextMenu/DocumentContextMenu.types';
import {
  ComponentProcessingStatus,
  DocumentResponse,
  DOCUMENTS_LIST_API_ENDPOINTS,
  DOCUMENTS_LIST_API_TAGS,
} from '../../../store/files/documents/documents.list.types';
import { changeDocumentProcessingStatusViewModal } from '../../../store/files/documents/documents.slice';
import { useAppDispatch } from '../../../store/hooks';
import { documentsListApi } from '../../../store/files/documents/documents.list.service';
import { store } from '../../../store/store';
import { BOOLEAN_VALUES } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';

export const updateDocumentListAction = (
  documentId: string,
  processingStatus: ComponentProcessingStatus
) => {
  const { originalArgs } =
    documentsListApi.util
      .selectInvalidatedBy(store.getState(), [{ type: DOCUMENTS_LIST_API_TAGS.DOCUMENTS_LIST }])
      .find(
        ({ endpointName, originalArgs }) =>
          endpointName === DOCUMENTS_LIST_API_ENDPOINTS.GET_DOCUMENTS_LIST &&
          originalArgs.IsDraft === BOOLEAN_VALUES.TRUE
      ) || {};

  return documentsListApi.util.updateQueryData(
    DOCUMENTS_LIST_API_ENDPOINTS.GET_DOCUMENTS_LIST,
    originalArgs,
    (documents) => {
      const listDocument = documents.Documents.find((doc) => doc.DocumentId === documentId);
      if (listDocument) {
        listDocument.ComponentProcessingStatus = processingStatus;
      }
    }
  );
};

export const useFetchDocumentComponentsHook = (
  document: DocumentResponse | undefined,
  setLanguage: LanguageToggleSwitchHookReturn[1],
  componentsEnabled?: boolean
) => {
  const dispatch = useAppDispatch();

  const statusPendingDone = [
    ComponentProcessingStatus.PENDING,
    ComponentProcessingStatus.DONE,
  ].includes(document?.ComponentProcessingStatus!);

  const onItemClick: DocumentContextMenuItemClick = (item) => {
    if (item === DOCUMENT_CONTEXT_MENU_ITEM_NAME.SEGMENTATION) {
      setLanguage(LanguageToggleSwitchTypes.Components);

      dispatch(changeDocumentProcessingStatusViewModal(ComponentProcessingStatus.PENDING));
      dispatch(updateDocumentListAction(document?.DocumentId!, ComponentProcessingStatus.PENDING));
    }
  };

  return {
    onItemClick,
    showComponents: componentsEnabled && statusPendingDone,
  };
};
