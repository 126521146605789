import React from 'react';
import './AIComparisonPendingModal.scss';
import { MarsModal } from '../../../StaticComponents/Modals/MarsModal';
import CircularProgress from '@mui/material/CircularProgress';
import { AIComparisonPendingModalMessages } from './DocumentAIComparison.messages';

interface AIComparisonPendingModalProps {
  open: boolean;
  onClose: () => void;
}

export const AIComparisonPendingModal: React.FC<AIComparisonPendingModalProps> = ({
  open,
  onClose,
}) => {
  return (
    <MarsModal
      open={open}
      onClose={onClose}
      showCancelButton={false}
      buttonLabel='Close'
      modalClassName='ai-comparison-pending-modal-wrapper'
      BackdropProps={{
        timeout: 0,
      }}
    >
      <div className='ai-comparison-pending-modal'>
        <CircularProgress className='spinner' />
        <h3 className='title'>{AIComparisonPendingModalMessages.MODAL_TITLE}</h3>
        <div className='message'>{AIComparisonPendingModalMessages.MODAL_MESSAGE_1}</div>
        <div className='location-info'>
          <span>{AIComparisonPendingModalMessages.MODAL_MESSAGE_2}</span>
          <span className='location-path'>{AIComparisonPendingModalMessages.MODAL_MESSAGE_3}</span>
        </div>
      </div>
    </MarsModal>
  );
};
