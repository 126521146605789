import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { DocumentComponentsPendingProps } from './DocumentComponents.types';

export const DocumentComponentsPending = ({ error, pending }: DocumentComponentsPendingProps) => {
  if (error)
    return (
      <div className='document-components-pending'>
        <ErrorOutlineIcon />
      </div>
    );
  else if (pending)
    return (
      <div className='document-components-pending'>
        <CircularProgress />
        <strong>Pending...</strong>
        <div>
          The Segmentation process might take a while.
          <br />
          Once it is complete the results will be visible in Components tab of the document preview.
        </div>
      </div>
    );
  else
    return (
      <div className='document-components-pending'>
        <CircularProgress />
      </div>
    );
};
