import React, { useEffect } from 'react';
import { ClauseViewerProps } from './ClauseViever.types';
import { TxtViewer } from '../../StaticComponents/FileViewer/TxtViewer';
import { LanguageToggleSwitchTypes } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.types';
import { useGetClauseFileQuery } from '../../../store/files/clauses/clauses.list.service';
import { ClauseFileResponseFields } from '../../../store/files/clauses/clauses.list.types';
import { useSearch } from '../../StaticComponents/FileViewer/ViewerToolbar/SearchContext';
import SpecialCharacterTextEditorWithDownload from '../../UploadFiles/MetadataEditModal/SpecialCharacterTextEditorWithDownload';
import { Controller } from 'react-hook-form';
import { ClauseMetadataFields } from '../../UploadClause/UploadClause.types';
import { useDetermineViewerLanguage } from '../../UploadFiles/MetadataEditModal/AchDocumentViewer.hooks';

export const ClauseViewer = ({
  clauseId = '',
  language,
  keyword,
  method,
  order,
  searchOption,
  formMethods,
  synonyms,
}: ClauseViewerProps) => {
  const { data, isFetching, isError } = useGetClauseFileQuery(clauseId, {
    skip: !clauseId,
  });

  const { setSearchTerm } = useSearch();
  useEffect(() => {
    const searchTermPrefill = keyword ? keyword : '';
    setSearchTerm(searchTermPrefill);
  }, [keyword, setSearchTerm]);

  const viewerLanguage = useDetermineViewerLanguage(
    language,
    order,
    data?.[ClauseFileResponseFields.TranslatedTxtUrl]
  );

  switch (viewerLanguage) {
    case LanguageToggleSwitchTypes.Original: {
      const url = data?.[ClauseFileResponseFields.OriginalTxtUrl];
      return formMethods ? (
        <Controller
          control={formMethods?.control}
          name={ClauseMetadataFields.Content}
          render={({ field }) => (
            <SpecialCharacterTextEditorWithDownload
              url={url}
              loading={isFetching}
              error={isError}
              value={field.value}
              onChange={field.onChange}
              setValue={formMethods.setValue}
            />
          )}
        />
      ) : (
        <TxtViewer
          loading={isFetching}
          error={isError}
          url={url}
          keyword={keyword}
          method={method}
          order={order}
          searchOption={searchOption}
          synonyms={synonyms}
        />
      );
    }
    case LanguageToggleSwitchTypes.English: {
      const url = data?.[ClauseFileResponseFields.TranslatedTxtUrl];
      return (
        <TxtViewer
          loading={isFetching}
          error={isError}
          url={url}
          keyword={keyword}
          method={method}
          order={order}
          searchOption={searchOption}
          synonyms={synonyms}
        />
      );
    }
  }

  return null;
};
