import { useMemo } from 'react';

import { DocumentMetadata, DocumentMetadataFields } from './MetadataForm.types';
import { BOOLEAN_VALUES, NOT_SELECTED } from './MetadataFormHelpers';

import { MAX_PAGE_SIZE, DOCUMENT_TYPES } from '../../../config/config';
import {
  DocumentResponse,
  DocumentStatus,
} from '../../../store/files/documents/documents.list.types';
import { GetDocumentDTO } from '../../../store/files/documentsAndClauses/list.dto.types';
import { useGetDocumentsListQuery } from '../../../store/files/documents/documents.list.service';

const AUTOMATED_UPLOAD = 'Automated Upload';
export const SEARCH_BY_PROVIDED_PARENT_DOCUMENTS_ID = 'searchByProvidedParentDocumentsId';

type UseCaseType =
  | DocumentMetadataFields.ParentDocuments
  | DocumentMetadataFields.VersionKey
  | typeof SEARCH_BY_PROVIDED_PARENT_DOCUMENTS_ID;

const getQueryParamsBasedOnUseCase = (
  useCase: UseCaseType,
  { Lob, Lop, DocumentType, Entity, DocumentId, ParentDocuments = [], IsDraft }: DocumentMetadata
): GetDocumentDTO => {
  const baseFilters = {
    Entity: [Entity ?? ''],
    Lop: [Lop ?? ''],
    Lob: [Lob ?? ''],
    IsDraft: [IsDraft ? BOOLEAN_VALUES.TRUE : BOOLEAN_VALUES.FALSE],
  };

  let includeFilters;
  switch (useCase) {
    case DocumentMetadataFields.ParentDocuments:
      includeFilters = {
        ...baseFilters,
        DocumentType: [
          DOCUMENT_TYPES.STANDARD_GENERAL_CONDITION,
          DOCUMENT_TYPES.STANDARD_SPECIAL_CONDITION,
        ],
        Classification: ['C1', 'C2', 'C3'],
      };
      break;
    case DocumentMetadataFields.VersionKey:
      includeFilters = {
        ...baseFilters,
        DocumentType: [DocumentType ?? ''],
      };
      break;
    case SEARCH_BY_PROVIDED_PARENT_DOCUMENTS_ID:
      includeFilters = { DocumentId: [...ParentDocuments] };
      break;
  }

  return {
    PageSize: MAX_PAGE_SIZE,
    IncludeFilters: {
      Status: [DocumentStatus.READY],
      ...includeFilters,
    },
    ExcludeFilters: {
      DocumentId: DocumentId ? [DocumentId] : undefined,
      UploadedBy: [AUTOMATED_UPLOAD],
    },
  };
};

const mapDocuments = (Documents: DocumentResponse[] | undefined, useCase: UseCaseType) =>
  Documents
    ? Documents.map((doc) => ({
        label: doc.FileName,
        label2: `Version ${doc.Version}`,
        value: useCase === DocumentMetadataFields.VersionKey ? doc.VersionKey : doc.DocumentId,
      }))
    : [];

const getDocumentOptions = (
  returnOnlyNonSelected: boolean,
  useCase: UseCaseType,
  mappedDocs: { label: string; label2: string; value: string }[]
) => {
  const nonSelectedOption = [{ label: NOT_SELECTED, value: NOT_SELECTED, label2: '' }];

  if (useCase === SEARCH_BY_PROVIDED_PARENT_DOCUMENTS_ID) {
    return [...mappedDocs];
  }
  if (returnOnlyNonSelected) {
    return nonSelectedOption;
  }
  return nonSelectedOption.concat(mappedDocs);
};

export const useRelatedDocumentsValues = (
  relatedDocumentMetadata: DocumentMetadata,
  useCase: UseCaseType
) => {
  const { Lob, Lop, DocumentType, ParentDocuments, Entity } = relatedDocumentMetadata;

  const baseValuesProvidedVersionKey = !!Lob && !!Lop && !!DocumentType && !!Entity;
  const baseValuesProvidedParentDocuments =
    baseValuesProvidedVersionKey && DocumentType !== DOCUMENT_TYPES.STANDARD_GENERAL_CONDITION;

  const baseValuesProvided =
    useCase === DocumentMetadataFields.VersionKey
      ? baseValuesProvidedVersionKey
      : useCase === DocumentMetadataFields.ParentDocuments
      ? baseValuesProvidedParentDocuments
      : baseValuesProvidedVersionKey && !!ParentDocuments?.length;

  const params = getQueryParamsBasedOnUseCase(useCase, relatedDocumentMetadata);
  const { data: { Documents } = {}, isLoading } = useGetDocumentsListQuery(params, {
    skip: !baseValuesProvided,
  });

  return useMemo(() => {
    if (isLoading) {
      return undefined;
    }
    const mappedDocs = mapDocuments(Documents, useCase);
    return getDocumentOptions(!baseValuesProvided, useCase, mappedDocs);
  }, [baseValuesProvided, useCase, Documents, isLoading]);
};
