import React from 'react';
import Typography from '@mui/material/Typography';
import common from '../../../assets/common.module.scss';
import { getSynonym } from '../DocumentsCount.helpers';
import { BasePopoverButton } from '../../StaticComponents/PopoverButton/BasePopoverButton';
import { SynonymPopoverProps } from './SynonymPopover.types';

export const SynonymPopover = ({
  synonymSearch,
  queriesSynonyms,
  loading,
}: SynonymPopoverProps) => {
  const synonymsString = queriesSynonyms?.join(', ');

  return (
    <span>
      {getSynonym(synonymSearch)}
      <BasePopoverButton disabled={!synonymsString} loading={loading}>
        <Typography
          sx={{
            lineHeight: '20px',
            color: common.midGray,
            p: '0 10px',
          }}
        >
          {synonymsString}
        </Typography>
      </BasePopoverButton>
    </span>
  );
};
