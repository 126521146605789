import React from 'react';
import Typography from '@mui/material/Typography';
import common from '../../../assets/common.module.scss';
import { ExcludedWordsProps } from './ExcludedWords.types';
import { TagInput } from './TagInput/TagInput';
import { BasePopoverButton } from '../../StaticComponents/PopoverButton/BasePopoverButton';

export const ExcludedWords = ({
  clauseMode = false,
  label,
  onChange,
  value = [],
}: ExcludedWordsProps) => {
  return (
    <div className='documents-search-excluded'>
      <span className='documents-search-counter'>{value.length ? value.length : '-'}</span>
      <Typography component='span'>Exclude words</Typography>

      <BasePopoverButton>
        <Typography
          sx={{
            fontSize: 12,
            lineHeight: '20px',
            color: common.midGray,
            p: '0 10px 8px',
          }}
        >
          Search only for {clauseMode ? 'clauses' : 'documents'} that do not contain the words
          entered below
        </Typography>

        <TagInput
          onChange={onChange}
          placeholder='Type excluded text here and press Enter'
          value={value}
          small
        />
      </BasePopoverButton>

      {label}
    </div>
  );
};
