export enum ReferenceDocumentSelectionModalMessages {
  MODAL_TITLE = 'Select the reference document',
  MODAL_MESSAGE = 'You have to choose the reference document for the analysis. If the selected choice below is incorrect please choose the right document instead.',
  VALIDATION_ERROR = "This document hasn't been split into components yet. Please do the segmentation first or choose a different document as reference document.",
  BUTTON_LABEL = 'Confirm',
}

export enum AIComparisonPendingModalMessages {
  MODAL_TITLE = 'Analyzing documents...',
  MODAL_MESSAGE_1 = 'The analysis process might take a while.',
  MODAL_MESSAGE_2 = 'Once it is complete the results will be saved in',
  MODAL_MESSAGE_3 = ' Sandbox > Wording analysis',
}
