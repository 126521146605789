import {
  DocumentResponse,
  DocumentsListParams,
  DocumentsListResponse,
} from '../documents/documents.list.types';
import {
  ClauseResponse,
  ClausesListParams,
  ClausesListResponse,
} from '../clauses/clauses.list.types';
import { ClauseFilters, DocumentFilters, GetClauseDTO, GetDocumentDTO } from './list.dto.types';
import { AccessMode } from './list.types';
import { omitBy } from 'lodash';
import { transformApprovalStatus } from '../../../components/Documents/DocumentsSearch/ClauseSearchFields.helpers';

export const getFilteredParams = (values: DocumentsListParams | ClausesListParams) =>
  Object.entries(values).filter(([, value]) => value?.length);

export const prepareQueryString = (params: object) => {
  return getFilteredParams(params)
    .flatMap(([key, values]) =>
      Array.isArray(values)
        ? values.map((v: string) => `${key}=${encodeURIComponent(v)}`)
        : `${key}=${encodeURIComponent(values)}`
    )
    .join('&');
};

export function isDocumentResponse(
  item: DocumentResponse | ClauseResponse | undefined
): item is DocumentResponse {
  return item !== undefined && 'DocumentId' in item;
}

export function isDocumentListResponse(
  response: DocumentsListResponse | ClausesListResponse | undefined
): response is DocumentsListResponse {
  return response !== undefined && 'Documents' in response;
}

export function isClauseResponse(
  item: DocumentResponse | ClauseResponse | undefined
): item is ClauseResponse {
  return item !== undefined && 'ClauseId' in item;
}

export const mapToDocumentFilters = (cleanParams: DocumentsListParams): DocumentFilters => {
  return {
    Active: cleanParams.Active || undefined,
    BusinessType: cleanParams.BusinessType,
    Classification: cleanParams.Classification,
    Country: cleanParams.Country,
    DocumentId: cleanParams.DocumentId,
    DocumentType: cleanParams.DocumentType,
    Entity: cleanParams.Entity,
    ExternalId: cleanParams.ExternalId ? [cleanParams.ExternalId] : undefined,
    ExternalVersion: undefined,
    Language: cleanParams.Language,
    LatestVersion: cleanParams.LatestVersion || undefined,
    Lob: cleanParams.Lob,
    Lop: cleanParams.Lop,
    ParentDocuments: cleanParams.ParentDocuments,
    Reported: cleanParams.Reported ? [cleanParams.Reported] : undefined,
    RelatedClauses: cleanParams.RelatedClauses ? [cleanParams.RelatedClauses] : undefined,
    Status: cleanParams.Status,
    VersionKey: cleanParams.VersionKey ? [cleanParams.VersionKey] : undefined,
    IsDraft: cleanParams.IsDraft ? [cleanParams.IsDraft] : undefined,
  };
};
export const mapDocumentsListParamsToGetDocumentDTO = (
  params: DocumentsListParams
): GetDocumentDTO => {
  const cleanParams = omitBy(params, (v) => Array.isArray(v) && !v?.length) as DocumentsListParams;
  const documentFilters = mapToDocumentFilters(cleanParams);
  return {
    AccessMode: cleanParams.AccessMode as unknown as AccessMode,
    BookmarkId: cleanParams.BookmarkId,
    ExcludeFilters: {},
    IncludeFilters: Object.values(documentFilters).some(Boolean) ? documentFilters : {},
    PageNumber: cleanParams.PageNumber ? parseInt(cleanParams.PageNumber) : undefined,
    PageSize: cleanParams.PageSize ? parseInt(cleanParams.PageSize) : undefined,
    Queries: cleanParams.Queries?.map((v) => ({
      ExcludePhrases: v.ExcludePhrases,
      OriginalLanguage: v.OriginalLanguage,
      SearchMethod: v.SearchMethod,
      SearchQuery: v.SearchQuery,
      SynonymSearch: v.SynonymSearch,
    })),
    SortBy: cleanParams.SortBy,
    SortOrder: cleanParams.SortOrder,
  };
};

export const mapToClauseFilters = (cleanParams: ClausesListParams): ClauseFilters => {
  const approvalStatus = transformApprovalStatus(cleanParams.ApprovalStatus);

  return {
    BusinessTypes: cleanParams.BusinessTypes,
    Classification: cleanParams.Classifications,
    Countries: cleanParams.Countries,
    ClauseId: cleanParams.ClauseId,
    ClauseTypes: cleanParams.ClauseTypes,
    ClauseSources: cleanParams.ClauseSources,
    ClauseStatus: cleanParams.ClauseStatus,
    Entities: cleanParams.Entities,
    Language: cleanParams.Language,
    Lobs: cleanParams.Lobs,
    Lops: cleanParams.Lops,
    Provider: cleanParams.Provider,
    Reported: cleanParams.Reported ? [cleanParams.Reported] : undefined,
    Status: cleanParams.Status,
    Tag: cleanParams.Tag,
    IsDraft: cleanParams.IsDraft ? [cleanParams.IsDraft] : undefined,
    ComplianceApproved: approvalStatus.IncludeFilters.ComplianceApproved,
    LegalApproved: approvalStatus.IncludeFilters.LegalApproved,
  };
};
export const mapClauseListParamsToGetClauseDTO = (params: ClausesListParams): GetClauseDTO => {
  const cleanParams = omitBy(params, (v) => Array.isArray(v) && !v?.length) as ClausesListParams;
  const clauseFilters = mapToClauseFilters(cleanParams);
  return {
    AccessMode: cleanParams.AccessMode as unknown as AccessMode,
    BookmarkId: cleanParams.BookmarkId,
    ExcludeFilters: undefined,
    IncludeFilters: Object.values(clauseFilters).some(Boolean) ? clauseFilters : undefined,
    PageNumber: cleanParams.PageNumber ? parseInt(cleanParams.PageNumber ?? '1') : undefined,
    PageSize: cleanParams.PageSize ? parseInt(cleanParams.PageSize ?? '10') : undefined,
    Queries: cleanParams.Queries?.map((v) => ({
      ExcludePhrases: v.ExcludePhrases,
      OriginalLanguage: v.OriginalLanguage,
      SearchMethod: v.SearchMethod,
      SearchQuery: v.SearchQuery,
      SynonymSearch: v.SynonymSearch,
    })),
    SortBy: cleanParams.SortBy,
    SortOrder: cleanParams.SortOrder,
  };
};
export const transformTagsBody = (tags: string[]) =>
  tags.map((tag) => ({ value: tag, label: tag }));
