import { ContextMenuProps } from './ContextMenu.types';
import { Menu } from '@mui/material';
import {
  menuItemDisabled,
  menuItemVisible,
} from '../Documents/DocumentContextMenu/DocumentContextMenu.helpers';
import { DOCUMENT_CONTEXT_MENU_ITEM_NAME } from '../Documents/DocumentContextMenu/DocumentContextMenu.types';
import { ContextMenuDownload } from './ContextMenuDownload';
import { MenuItemsStatusDone, MenuItemsStatusPending } from './ContextMenu.helpers';
import { COMPONENT_COMPARISON_STATUS } from '../../store/files/documents/documents.list.types';

export const ContextMenu: React.FC<ContextMenuProps> = ({
  control: { selectedItem, anchorEl, onClose },
}) => {
  const menuItems =
    selectedItem?.TaskStatus === COMPONENT_COMPARISON_STATUS.DONE
      ? MenuItemsStatusDone
      : MenuItemsStatusPending;

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      className='documents-list-menu'
    >
      {menuItemVisible(menuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD) ? (
        <ContextMenuDownload
          disabled={menuItemDisabled(menuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD)}
          item={selectedItem}
          handleClose={onClose}
        />
      ) : null}
    </Menu>
  );
};
