import { useDraftDocumentWriteAccess } from '../../contexts/UserAccess';
import { Tabs } from '../StaticComponents/Tabs/Tabs';
import { RoutePath } from '../../services/route.service';
import { TAB_NAMES } from '../../config/config';
import FilesTab from './FilesTab';

const WordingAnalysis = () => {
  useDraftDocumentWriteAccess();

  return (
    <div className='reports'>
      <div className='reports-title'>
        <span>Wording analysis</span>
      </div>
      <Tabs
        base={RoutePath.SANBOX_ANALYSIS}
        components={[
          {
            label: TAB_NAMES.FILES,
            url: RoutePath.FILES,
            component: <FilesTab />,
          },
        ]}
      />
    </div>
  );
};

export default WordingAnalysis;
