import { useCallback, useEffect, useState } from 'react';
import { DirectionFlag, ZoomScales } from './FileViewer.types';
import { calculateScale } from './PdfViewer.helpers';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { lockZoom, setHighlightDetails, setZoomLock } from '../../../store/ui/ui.slice';
import { useSearch } from './ViewerToolbar/SearchContext';

const TAG = 'mark';
const SYNONYM = 'synonym';
const CURRENT = 'current';
const TAG_SYNONYM = `${TAG}, ${SYNONYM}`;

export const getFirstMarkIndex = (element: HTMLElement) => {
  let current: number | undefined;
  element.querySelectorAll(TAG_SYNONYM).forEach((el, index) => {
    if (current === undefined && el.tagName.toLowerCase() === TAG) {
      current = index;
    }
  });
  return current ?? 0;
};

export const useMarkSwitchForTxtViewer = () => {
  const dispatch = useAppDispatch();
  const { searchTerm } = useSearch();
  const [current, setCurrent] = useState(0);
  const [document, setDocument] = useState<HTMLElement | undefined>();

  const updateHighlightState = useCallback(() => {
    if (!document) return;
    const marks = document.querySelectorAll(TAG_SYNONYM);
    const totalHighlights = marks ? marks.length : 0;
    const currentHighlight = current + 1;
    dispatch(setHighlightDetails({ totalHighlights, currentHighlight }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, document, current, searchTerm]);

  const switchMark = useCallback(() => {
    if (!document) return;
    const marks = document.querySelectorAll(TAG_SYNONYM);
    marks?.forEach((e) => e.classList.remove(CURRENT));

    const mark = marks[current];
    mark?.classList.add(CURRENT);
    mark?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  }, [current, document]);

  useEffect(() => {
    switchMark();
    updateHighlightState();
  }, [current, document, switchMark, updateHighlightState]);

  useEffect(() => {
    if (!document) return;
    setCurrent(getFirstMarkIndex(document));
  }, [document, searchTerm]);

  const initialize = useCallback((doc?: HTMLElement | null) => {
    doc && setDocument(doc);
  }, []);

  const handleSwitch = useCallback(
    (direction: DirectionFlag = 1) => {
      if (!document) return;
      const marks = document.querySelectorAll(TAG_SYNONYM);

      const next = current + direction;
      setCurrent(next === marks.length ? 0 : next === -1 ? marks.length - 1 : next);

      switchMark();
    },
    [current, document, switchMark]
  );

  return { initialize, handleSwitch, switchMark };
};

export const useZoomSwitch = (
  url: string | undefined,
  [scales, initial]: ZoomScales,
  isDocument: boolean
) => {
  const dispatch = useAppDispatch();
  const initialScale = scales[initial];
  const [scale, setScale] = useState(initialScale);

  const zoomLock = useAppSelector(uiSelectors.selectZoomLock);
  const zoomLocked = useAppSelector(uiSelectors.selectZoomLocked);

  useEffect(() => {
    setScale(initialScale);
  }, [initialScale, url, zoomLocked]);

  useEffect(() => {
    if (zoomLocked) dispatch(lockZoom(isDocument));
  }, [dispatch, isDocument, url, zoomLocked]);

  const handleZoom = (dir: DirectionFlag = 1) => {
    if (zoomLocked) {
      dispatch(setZoomLock(calculateScale(zoomLock, dir, scales)));
    } else {
      setScale(calculateScale(scale, dir, scales));
    }
  };
  return { handleZoom, scale: zoomLocked ? zoomLock : scale };
};
