import { GridSortDirection } from '@mui/x-data-grid/models/gridSortModel';
import { OriginalLanguage, SynonymSearch } from '../documents/documents.list.types';
import { BOOLEAN_VALUES } from '../../../components/UploadFiles/MetadataForm/MetadataFormHelpers';

export const SEQUENTIAL_SEARCH = 'Queries';

export interface ListParams {
  AccessMode?: string;
  PageNumber?: string;
  PageSize?: string;
  Queries?: SequentialSearch[];
  Reported?: BOOLEAN_VALUES;
  SortBy?: string;
  SortOrder?: SortOrder;
  IsDraft?: BOOLEAN_VALUES;
}

export interface SequentialSearch {
  SynonymSearch?: SynonymSearch;
  ExcludePhrases?: string[];
  OriginalLanguage?: OriginalLanguage;
  SearchMethod?: SearchMethod;
  SearchQuery?: string;
}

export type SortOrder = Exclude<GridSortDirection, null>;

export enum AccessMode {
  READ = 'R',
  WRITE = 'W',
}

export enum SearchMethod {
  EXACT = 'exact',
  BROAD = 'broad',
  PHRASE = 'phrase',
}
