import React, { FormEvent } from 'react';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import { MarsModal } from '../../StaticComponents/Modals/MarsModal';
import '../../Documents/SingleInputModal.scss';
import { handleApiError } from '../../../store/error.helpers';
import { useForm } from 'react-hook-form';
import { ApiError } from '../../UploadFiles/MetadataForm/ApiError';
import { useConfirmationModal } from '../../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.hook';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MenuItem from '@mui/material/MenuItem';
import { NameInput } from '../NameInput/NameInput';
import { Name } from '../NameInput/NameInput.types';
import { ContextMenuEditNameProps } from './ContextMenuEditName.types';
import { useApiError } from '../../../store/ui/ui.helpers';

export const ContextMenuEditName: React.FC<ContextMenuEditNameProps> = ({
  id,
  onClose,
  name,
  updateMutation,
  itemType,
  disabled,
  sanitizeExtension,
}) => {
  const { apiError, setApiError, clearApiError } = useApiError();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    getValues,
    formState: { isValid, isDirty },
  } = useForm<Name>({
    mode: 'onChange',
  });

  const [update, { isLoading }] = updateMutation();
  const { open, handleOpen, handleClose: handleCloseModal } = useConfirmationModal();
  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    setApiError(undefined);
    const { Name } = getValues();
    if (Name && id) {
      update({ name: Name, id })
        .unwrap()
        .then(() => {
          handleCloseModal();
          onClose();
          setApiError('');
          enqueueSnackbar(
            itemType.charAt(0).toUpperCase() +
              itemType.slice(1).toLowerCase() +
              ' updated successfully'
          );
        })
        .catch((response) => setApiError(handleApiError(response, true)));
    }
  };

  return (
    <>
      <MenuItem onClick={handleOpen} disabled={disabled}>
        <EditOutlinedIcon />
        Edit
      </MenuItem>
      <MarsModal
        className='single-input-modal'
        title='Edit name'
        buttonLabel='Save'
        open={open}
        onConfirm={handleSubmit}
        onClose={handleCloseModal(onClose)}
        disabled={!isValid || !isDirty}
        loading={isLoading}
      >
        <Typography className='single-input-modal-description' variant='body2'>
          Please enter a new name for the {itemType.toLowerCase()}
        </Typography>

        <form className='single-input-modal-form' onSubmit={handleSubmit}>
          <NameInput
            control={control}
            isLoading={isLoading}
            name={name}
            itemType={itemType}
            clearApiError={clearApiError}
            sanitizeExtension={sanitizeExtension}
          />
        </form>

        <ApiError apiError={apiError} />
      </MarsModal>
    </>
  );
};
