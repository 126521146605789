import {
  ComponentProcessingStatus,
  DocumentComponentsResponse,
  DocumentComponentStatus,
  DocumentComponentType,
} from '../../../store/files/documents/documents.list.types';

export interface DocumentComponentsViewerProps {
  documentId: string;
  componentProcessingStatus?: ComponentProcessingStatus;
}

export interface DocumentComponentsPendingProps {
  error?: boolean;
  pending?: boolean;
}

export interface DocumentComponentsListProps {
  documentId: string;
  components: DocumentComponentsResponse;
}

export interface DocumentComponentProps extends SplitProps, StatusProps {
  component: DocumentComponentType;
}

export interface DocumentComponentMergeProps {
  onClick: () => void;
}

export interface DocumentComponentSplitProps extends SplitProps {
  componentContent: string;
  onClose: () => void;
}

export interface DocumentComponentContextMenuProps {
  onClick: (action: DocumentComponentContextMenuAction) => void;
  component: DocumentComponentType;
}

export interface HandleSelectorProps {
  componentContent: string;
  onSelect: (offsets?: number[]) => void;
}

interface SplitProps {
  onConfirm: (offsets: number[]) => void;
}

interface StatusProps {
  onStatusChange: (status: DocumentComponentStatus) => void;
}

export enum DocumentComponentContextMenuAction {
  MARK_AS_IRRELEVANT,
  SPLIT,
}
