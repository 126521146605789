import React, { useState } from 'react';
import {
  INIT_NUMBER_OF_DOCUMENTS,
  NUMBER_OF_ITEMS_ALLOWED,
  REPORTS_LIST_REFRESH_INTERVAL,
} from '../../config/config';
import { useAppSelector } from '../../store/hooks';
import { uiSelectors } from '../../store/ui/ui.selectors';
import { useDispatchSelectDocs } from '../SpreadSheet/DocumentsGrid/AchDocumentsGrid.hooks';
import { useContextMenu } from '../Documents/DocumentContextMenu/DocumentContextMenu.hooks';
import { ComponentComparison } from '../../store/files/documents/documents.list.types';
import { useComponentComparisonQuery } from '../../store/files/documents/documents.list.service';
import { Box, CircularProgress, Paper } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getColumns, isRowSelectable } from './FilesTab.helpers';
import { paginationOptions } from '../StaticComponents/CustomPagination/CustomPagination';
import { NoItemsFound } from '../StaticComponents/NoItemsFound/NoItemsFound';
import { ContextMenu } from './ContextMenu';

const FilesTab = () => {
  const [pageSize, setPageSize] = useState<number>(INIT_NUMBER_OF_DOCUMENTS);
  const selected = useAppSelector(uiSelectors.selectSelectedItem);
  const dispatchSelectDocs = useDispatchSelectDocs();
  const { control, handleContextMenu } = useContextMenu<ComponentComparison>();

  const { data, isLoading } = useComponentComparisonQuery(undefined, {
    pollingInterval: REPORTS_LIST_REFRESH_INTERVAL,
    refetchOnMountOrArgChange: true,
  });

  return (
    <Paper elevation={0} className='documents-list'>
      {isLoading ? (
        <Box sx={{ textAlign: 'center', padding: '30px' }}>
          <CircularProgress />
        </Box>
      ) : data?.length ? (
        <DataGridPro
          sortModel={[{ field: 'TaskStatus', sort: 'asc' }]}
          className='documents-list-grid bookmarks-list-grid'
          headerHeight={32}
          autoHeight
          getRowId={(row) => row.Id}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          rows={data}
          rowsPerPageOptions={NUMBER_OF_ITEMS_ALLOWED}
          columns={getColumns(handleContextMenu)}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selected}
          onSelectionModelChange={(ids) => dispatchSelectDocs(ids as string[])}
          isRowSelectable={isRowSelectable}
          disableColumnMenu
          disableDensitySelector
          disableColumnReorder
          pagination
          componentsProps={{ pagination: paginationOptions() }}
        />
      ) : (
        <NoItemsFound
          icon='folderIconReports'
          subtitle='Your wording analysis results will be stored here.'
        />
      )}

      <ContextMenu control={control} />
    </Paper>
  );
};

export default FilesTab;
