import React, { useCallback } from 'react';
import { MenuItem } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useAppDispatch } from '../../store/hooks';
import { downloadDocumentComponentComparison } from '../../store/files/documents/documents.slice';
import { ContextMenuItemProps } from './ContextMenu.types';
import { getComparisonFileName } from './FilesTab.helpers';

export const ContextMenuDownload: React.FC<ContextMenuItemProps> = ({
  item,
  disabled,
  handleClose,
}) => {
  const dispatch = useAppDispatch();

  const handleDownloadDocument = useCallback(() => {
    if (item) {
      const fileName = getComparisonFileName(item);
      dispatch(downloadDocumentComponentComparison({ fileName, comparisonId: item.Id }));
      handleClose();
    }
  }, [dispatch, handleClose, item]);

  return (
    <MenuItem onClick={handleDownloadDocument} disabled={disabled}>
      <FileDownloadOutlinedIcon className='document-view-icons' />
      Download
    </MenuItem>
  );
};
