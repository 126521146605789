import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ClauseDictionariesResponse } from './list.service.types';
import { getGermanEntitiesSubsetFromFilteredValues } from '../../../components/Documents/DocumentsSearch/DocumentFilter.helpers';

const selectDictionariesData = (state: RootState): ClauseDictionariesResponse | undefined =>
  state.listApi.queries['getClauseDictionaries({"AccessMode":"R"})']?.data as
    | ClauseDictionariesResponse
    | undefined;

export const selectAllGermanCompanies = createSelector(
  [selectDictionariesData],
  (dictionariesData) =>
    dictionariesData?.Entities?.values
      ? getGermanEntitiesSubsetFromFilteredValues(dictionariesData.Entities.values)
      : []
);

const createSelectAllSelector = (key: 'Entities' | 'Lobs' | 'Countries') =>
  createSelector([selectDictionariesData], (dictionariesData) =>
    dictionariesData?.[key]?.values.map((item) => item.value)
  );

export const selectAllCompanies = createSelectAllSelector('Entities');
export const selectAllLobs = createSelectAllSelector('Lobs');
export const selectAllCountries = createSelectAllSelector('Countries');

export const dictionariesSelectors = {
  selectAllCompanies,
  selectAllGermanCompanies,
  selectAllLobs,
  selectAllCountries,
};
