import { escapeRegExp } from 'lodash';
import { QuerySynonyms } from '../../../store/files/documents/documents.list.types';

const highlight = '<mark>$1</mark>';
const synonymHighlight = '<synonym>$1</synonym>';
const matchOutsideHtmlTags = '(?!([^<]+)?>)';

const searchHighlightDefault = (text: string, phrase: string) => {
  const keywords = escapeRegExp(phrase).split(' ');
  const regex = new RegExp(`(${keywords.join('|')})${matchOutsideHtmlTags}`, 'gi');
  return text.replaceAll(regex, highlight);
};

const searchHighlightExact = (text: string, phrase: string) => {
  const regex = new RegExp(`(${escapeRegExp(phrase)})${matchOutsideHtmlTags}`, 'gi');
  return text.replaceAll(regex, highlight);
};

const searchHighlightSynonyms = (text: string, phrase: string) => {
  const regex = new RegExp(`\\b(${escapeRegExp(phrase)})\\b${matchOutsideHtmlTags}`, 'gi');
  return text.replaceAll(regex, synonymHighlight);
};

export const searchHighlight = (
  text: string,
  phrase?: string,
  exact = false,
  synonyms?: QuerySynonyms
) => {
  let content = text;
  if (synonyms) {
    for (const synonym of synonyms) {
      content = searchHighlightSynonyms(content, synonym);
    }
  }
  if (phrase) {
    content = exact
      ? searchHighlightExact(content, phrase)
      : searchHighlightDefault(content, phrase);
  }
  return content;
};
