import {
  DocumentFilterLabelFunction,
  DocumentFilterProps,
  FirstParam,
  MENU_ITEM_GROUP,
} from './DocumentsSearch.types';
import { countryCodeEmoji } from 'country-code-emoji';
import { MetadataDictionaryValue } from '../../../store/files/upload/list.service.types';
import { DE } from '../../../config/config';

const isOptionAllSelected = (value?: string | string[]) =>
  Array.isArray(value) && value.indexOf('') > -1;

export const getValueOrEmptyArray = (
  value: DocumentFilterProps['value'],
  firstParam: FirstParam | null
) => (firstParam === FirstParam.NONE || isOptionAllSelected(value) ? [] : value);

const isOptionAllGermanSelected = (value?: string | string[]): boolean =>
  Array.isArray(value) && value.includes(MENU_ITEM_GROUP.ALL_GERMAN);

export const getGermanEntitiesSubsetFromFilteredValues = (
  filteredValues: MetadataDictionaryValue[]
) =>
  filteredValues.filter((v) => v.related?.Country === DE && v.value !== 'GPC').map((v) => v.value);

export const handleAllGermansSelection = (
  selectedValue: DocumentFilterProps['value'],
  selectableGermanCompanies: string[]
) => {
  if (isOptionAllGermanSelected(selectedValue) && Array.isArray(selectedValue)) {
    const selectionSet = new Set(
      selectedValue
        .filter((v) => v !== MENU_ITEM_GROUP.ALL_GERMAN)
        .concat(selectableGermanCompanies)
    );

    selectedValue = Array.from(selectionSet);
  }

  return selectedValue;
};

const getLabelByValue: (values: DocumentFilterProps['values']) => DocumentFilterLabelFunction =
  (values) => (value) =>
    values?.find((v) => v.value === value)?.label || '';

const getLabelWithFlag: (labelFn: DocumentFilterLabelFunction) => DocumentFilterLabelFunction =
  (labelFn) => (value) =>
    countryCodeEmoji(value) + ' ' + labelFn(value);

const getLabelForMultipleValues = (labelFn: DocumentFilterLabelFunction) => (value: string[]) =>
  value.map(labelFn).join(', ');

export const prepareLabel = (
  isCountry: DocumentFilterProps['isCountry'],
  multiple: DocumentFilterProps['multiple'],
  values: DocumentFilterProps['values'],
  selected: DocumentFilterProps['value'],
  firstParam: FirstParam | null
) => {
  if (selected?.length === 0) {
    return firstParam === FirstParam.NONE
      ? MENU_ITEM_GROUP.NONE
      : firstParam === FirstParam.ALL && MENU_ITEM_GROUP.ALL;
  } else {
    const labelFn = isCountry ? getLabelWithFlag(getLabelByValue(values)) : getLabelByValue(values);
    return multiple
      ? getLabelForMultipleValues(labelFn)(selected as string[])
      : labelFn(selected as string);
  }
};
