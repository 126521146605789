import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import { LabelWithInfo } from './LabelWithInfo';
import { CheckboxWithLabel } from '../../StaticComponents/CheckboxWithLabel';
import {
  isOriginalLanguage,
  isSynonymSearch,
  setOriginalLanguage,
  setSynonymSearch,
} from './DocumentsSearch.helpers';
import { ExcludedWords } from './ExcludedWords';
import { InfoButton } from './InfoButton';
import { getPrefixed } from './SearchOptions.helpers';
import { SearchOptionsProps } from './SearchOptions.types';
import { SEQUENTIAL_SEARCH } from '../../../store/files/documentsAndClauses/list.types';
import { useSelectExactSearchWhenSynonymEnabled } from './AdvancedOptions.hooks';

export const AdvancedOptions: React.FC<SearchOptionsProps> = ({
  clauseMode,
  control,
  prefix,
  index,
}) => {
  const prefixed = useMemo(() => getPrefixed(prefix), [prefix]);
  const synonymSearch = `${prefixed}${SEQUENTIAL_SEARCH}.${index}.SynonymSearch`;
  const originalLanguageName = `${prefixed}${SEQUENTIAL_SEARCH}.${index}.OriginalLanguage`;
  const excludePhrasesName = `${prefixed}${SEQUENTIAL_SEARCH}.${index}.ExcludePhrases`;

  const { setExactSearchOnSynonymEnabled } = useSelectExactSearchWhenSynonymEnabled({
    control,
    prefixed,
    index,
  });

  return (
    <div className='search-options'>
      <Typography variant='overline'>Advanced options</Typography>

      <FormGroup>
        <Controller
          control={control}
          name={synonymSearch}
          render={({ field }) => (
            <CheckboxWithLabel
              {...field}
              checked={isSynonymSearch(field.value)}
              onChange={(_, checked) => {
                field.onChange(setSynonymSearch(checked));
                setExactSearchOnSynonymEnabled(checked);
              }}
              label={
                <LabelWithInfo
                  title='Synonym search'
                  description={`Search for ${
                    clauseMode ? 'clauses' : 'documents'
                  } that contain synonyms or semantically related terms to provided search phrase.`}
                />
              }
            />
          )}
        />

        <Controller
          control={control}
          name={originalLanguageName}
          render={({ field }) => (
            <CheckboxWithLabel
              {...field}
              checked={isOriginalLanguage(field.value)}
              onChange={(_, checked) => field.onChange(setOriginalLanguage(checked))}
              label={
                <LabelWithInfo
                  title='Search in original language'
                  description='Check it if you want to search in other language than English i.e. in German or Polish.'
                />
              }
            />
          )}
        />

        <Controller
          control={control}
          name={excludePhrasesName}
          render={({ field: { onChange, value } }) => (
            <ExcludedWords
              onChange={onChange}
              value={value}
              clauseMode={clauseMode}
              label={
                <InfoButton
                  title='Exclude words'
                  description={
                    clauseMode
                      ? 'Search only for clauses that do not contain the words entered.'
                      : 'Search only for documents that do not contain the words entered.'
                  }
                />
              }
            />
          )}
        />
      </FormGroup>
    </div>
  );
};
