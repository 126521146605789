import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import ReportStatus from './ReportStatus';
import { Report, REPORT_STATUS } from '../../store/files/reports/reports.types';
import { DocumentContextMenuButton } from '../Documents/DocumentContextMenu/DocumentContextMenuButton';
import { ContextMenuHandler } from '../Documents/DocumentContextMenu/DocumentContextMenu.types';
import React from 'react';
import { FILE_EXTENSIONS } from '../../store/ui/ui.types';

export const getColumns = (handleMenuButtonClick: ContextMenuHandler<Report>): GridColDef[] => [
  {
    field: 'Name',
    headerName: 'REPORT NAME',
    flex: 10,
    sortable: false,
    renderCell: ({ row: { ReportId, ReportName } }) => {
      return <div>{ReportName ? ReportName : ReportId + FILE_EXTENSIONS.CSV}</div>;
    },
  },
  {
    field: 'Status',
    headerName: 'Status',
    flex: 5,
    sortable: false,
    sortComparator: (v1, v2) => statusSortOrder(v1) - statusSortOrder(v2),
    renderCell: ({ row: { Status } }: GridRenderCellParams) => {
      switch (Status) {
        case REPORT_STATUS.PROCESSED:
          return <ReportStatus mode='done' label='Done' />;
        case REPORT_STATUS.IN_PROCESSING:
        case REPORT_STATUS.REQUESTED:
          return <ReportStatus mode='pending' label='Pending' />;
        case REPORT_STATUS.FAILED:
          return <ReportStatus mode='failed' label='Failed' />;
      }
    },
  },
  {
    field: 'action',
    headerName: '',
    flex: 1,
    sortable: false,
    renderCell: function ButtonCell({ row }) {
      return (
        <DocumentContextMenuButton
          onClick={(event: React.MouseEvent<HTMLElement>) => handleMenuButtonClick(event, row)}
        />
      );
    },
  },
];

const statusSortOrder = (status: REPORT_STATUS) =>
  [
    REPORT_STATUS.FAILED,
    REPORT_STATUS.REQUESTED,
    REPORT_STATUS.IN_PROCESSING,
    REPORT_STATUS.PROCESSED,
  ].indexOf(status);

export const isReportRowSelectable = ({ row: { Status } }: GridRowParams<Report>) =>
  Status === REPORT_STATUS.PROCESSED || Status === REPORT_STATUS.FAILED;
