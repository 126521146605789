import React from 'react';
import { DroppableDocumentComparePanelProps } from './DocumentComparePanel.types';
import './DocumentComparePanel.scss';
import { useDrop } from 'react-dnd';
import { Identifier } from 'dnd-core';
import { DocumentComparePanel } from './DocumentComparePanel';
import { DraggableBarButtonItemType } from '../../../DocumentBar/BarButton/DraggableBarButton';
import { Order } from '../../../StaticComponents/FileViewer/useDocumentCompare';

export const DroppableDocumentComparePanel = ({
  documentDictionaries,
  document,
  index,
  onDrop,
  onLanguageChange,
}: DroppableDocumentComparePanelProps) => {
  const [{ handlerId, isOver }, ref] = useDrop<
    { id: string; index: number; type: string },
    void,
    { handlerId: Identifier | null; isOver: boolean }
  >({
    accept: DraggableBarButtonItemType,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
      isOver: monitor.isOver({ shallow: true }),
    }),
    drop(item) {
      onDrop && onDrop(item.index, index || 0);
    },
  });

  return (
    <DocumentComparePanel
      ref={ref}
      data-handler-id={handlerId}
      className={isOver ? 'document-compare-drop' : ''}
      documentDictionaries={documentDictionaries}
      document={document}
      key={document?.DocumentId}
      order={index as Order}
      onLanguageChange={onLanguageChange}
    />
  );
};
