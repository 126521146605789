import {
  ContextMenuItem,
  DOCUMENT_CONTEXT_MENU_ITEM_NAME,
} from '../Documents/DocumentContextMenu/DocumentContextMenu.types';

export const MenuItemsStatusDone: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD, disabled: false },
];
export const MenuItemsStatusPending: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD, disabled: true },
];
