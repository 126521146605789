import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import { ModalClose } from '../../StaticComponents/Modals/ModalClose';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import './AchMetadataEditModal.scss';
import { LanguageToggleSwitch } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch';
import { DocumentInfoHeader } from '../../StaticComponents/DocumentInfoHeader/DocumentInfoHeader';
import { useLanguageToggleSwitch } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.hook';
import { useLoadingWithTimeout } from '../../../store/files/documents/documents.hooks';
import { ClauseMetadataForm } from '../MetadataForm/ClauseMetadataForm';
import { clauseSelector } from '../../../store/files/clauses/clauses.selectors';
import { ClauseViewer } from '../../Clauses/ClauseViewModal/ClauseViewer';
import { closeClauseMetadataEditModal } from '../../../store/files/clauses/clauses.slice';
import LoadingButton from '@mui/lab/LoadingButton';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import { ClauseViewModalProps } from '../../Clauses/Clauses.types';
import { ApiError } from '../MetadataForm/ApiError';
import {
  clausesListApi,
  getClauseFileTag,
  useApproveClauseMutation,
  useDeleteClauseReportMutation,
  useGetInheritedMetadataQuery,
  useUpdateClausesMetadataMutation,
} from '../../../store/files/clauses/clauses.list.service';
import { useForm } from 'react-hook-form';
import { ClauseMetadata } from '../../UploadClause/UploadClause.types';
import { updateStatuses } from '../../../store/ui/ui.slice';
import {
  isAchResponseWithErrors,
  mapAchErrorResponseToErrorMessage,
} from '../../../store/files/documents/documents.update.success.helpers';
import { Messages } from '../../../services/messages.service.types';

import { handleApiError } from '../../../store/error.helpers';
import {
  mapToClauseMetadataUpdateRequestDto,
  removeNonEditableFields,
} from './AchClauseMetadataEditModal.helpers';
import { mapClauseSuccessResponseToUpdateStatuses } from '../../../store/files/clauses/clauses.update.success.helpers';
import { mapClauseErrorResponseToUpdateStatuses } from '../../../store/files/clauses/clauses.update.error.helpers';
import { ReportedField } from '../../SharedComponents/ReportedField/ReportedField';
import { useGetClauseDictionariesQueryDefaults } from '../../../store/files/upload/list.service.hooks';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import { MetadataDictionaryValue } from '../../../store/files/upload/list.service.types';
import { getLastNotEmptyQuery } from '../../Documents/DocumentViewModal/DocumentViewModal.helpers';
import { ErrorMessages } from '../../../services/errors.service.types';
import { useUserAccessContext } from '../../../contexts/UserAccess';

export const AchClauseMetadataEditModal = ({ useGetDataListWithParams }: ClauseViewModalProps) => {
  const [apiError, setApiError] = useState<string>();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const clause = useAppSelector(clauseSelector.selectModalClause);
  const [update] = useUpdateClausesMetadataMutation();
  const [approveClause] = useApproveClauseMutation();

  const [isClauseReportedChecked, setIsClauseReportedChecked] = useState<boolean>(false);
  const { data: dictionaries } = useGetClauseDictionariesQueryDefaults(
    { AccessMode: AccessMode.WRITE },
    { skip: !clause }
  );
  const [cancelClauseReport] = useDeleteClauseReportMutation();
  const { hasValidateModalAccess } = useUserAccessContext();

  const { data: inheritedMetadata, isLoading: inheritedMetadataIsLoading } =
    useGetInheritedMetadataQuery(clause?.ClauseId ?? '', { skip: !clause });

  const [nonEditableEntities, setNonEditableEntities] = useState<MetadataDictionaryValue[]>([]);

  const handleUpdateEntities = (nonEditableEntities: MetadataDictionaryValue[]) => {
    setNonEditableEntities(nonEditableEntities);
  };

  const {
    control,
    watch,
    trigger,
    formState: { isValid, isDirty },
    setValue,
    getValues,
    getFieldState,
    handleSubmit,
    reset,
  } = useForm<ClauseMetadata>({
    mode: 'onChange',
    defaultValues: clause,
  });

  const formMethods = {
    control,
    watch,
    trigger,
    setValue,
    getValues,
    getFieldState,
    reset,
  };

  const saveDisabled = (!isDirty || !isValid) && !isClauseReportedChecked;

  useEffect(() => {
    if (clause) {
      reset(clause);
    }
  }, [clause, reset]);

  const { filters } = useGetDataListWithParams();

  const { SearchQuery, OriginalLanguage, SearchMethod } = getLastNotEmptyQuery(filters.Queries);

  const [language, setLanguage] = useLanguageToggleSwitch(
    clause?.ClauseId,
    false,
    SearchQuery,
    OriginalLanguage
  );

  const isOpen = Boolean(clause);

  const { isLoading, startLoading, stopLoading, loadWithTimeout } = useLoadingWithTimeout(true);

  const handleClose = () => {
    dispatch(closeClauseMetadataEditModal());
    setApiError(undefined);
  };

  const handleCloseFromAPI = (successMsg: string) => {
    loadWithTimeout(() => {
      handleClose();
      dispatch(clausesListApi.util.invalidateTags(getClauseFileTag(clause!.ClauseId)));
      enqueueSnackbar(successMsg);
    });
  };

  const processFormData = (data: ClauseMetadata) => {
    if (!clause) return;
    startLoading();

    const isCancelReportCheckboxSelected = isClauseReportedChecked && clause?.ClauseId;
    if (isCancelReportCheckboxSelected) {
      cancelClauseReport(clause.ClauseId)
        .unwrap()
        .then(async () => {
          if (!isDirty) {
            handleCloseFromAPI(Messages.SuccessUpdate);
          }
        })
        .catch((err) => {
          stopLoading();
          setApiError(handleApiError(err));
        });
    }

    if (isDirty) {
      updateClause(data);
    }
  };

  const updateClause = (data: ClauseMetadata) => {
    const formDataWithoutNonEditable = removeNonEditableFields(data, nonEditableEntities);
    const mergedData = mapToClauseMetadataUpdateRequestDto(formDataWithoutNonEditable);

    const updatePayload = {
      clauseId: clause!.ClauseId,
      body: mergedData,
    };

    const approveClausePromise = hasValidateModalAccess
      ? approveClause({
          clauseId: clause!.ClauseId,
          Approved: data.Approved,
          ApprovalComment: data.Approved === 'False' ? data.ApprovalComment : '',
          ClauseStatus: data.ClauseStatus || undefined,
          Remarks: data.Remarks || undefined,
        }).unwrap()
      : null;

    Promise.all([approveClausePromise, update(updatePayload).unwrap()])
      .then(([approvalResponse, updateResponse]) => {
        dispatch(
          updateStatuses(mapClauseSuccessResponseToUpdateStatuses(updateResponse, clause!.ClauseId))
        );

        if (
          isAchResponseWithErrors(updateResponse) ||
          (approvalResponse && isAchResponseWithErrors(approvalResponse))
        ) {
          stopLoading();
          if (isAchResponseWithErrors(updateResponse))
            setApiError(mapAchErrorResponseToErrorMessage(updateResponse));
          else if (approvalResponse && isAchResponseWithErrors(approvalResponse)) {
            enqueueSnackbar(ErrorMessages.ValidateError, { variant: 'error' });
          }
        } else {
          handleCloseFromAPI(Messages.MetadataUpdateSuccess);
        }
      })
      .catch((err) => {
        stopLoading();
        dispatch(updateStatuses(mapClauseErrorResponseToUpdateStatuses(err, clause!.ClauseId)));
        setApiError(handleApiError(err));
      });
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className='metadata-edit-modal metadata-edit-modal-ach document-view-modal'>
        <Grid container>
          <Grid item xs className='document-view'>
            <div className='document-view-modal__top-left-bar'>
              <LanguageToggleSwitch value={language} onChange={setLanguage} />
            </div>
            <ClauseViewer
              clauseId={clause?.ClauseId}
              language={language}
              keyword={SearchQuery}
              method={SearchMethod}
              searchOption
              formMethods={formMethods}
            />
          </Grid>

          <Grid item className='form-view'>
            <div className='form-view__top-bar'>
              <ModalClose onClose={handleClose} />
            </div>
            <div className='metadata-edit-modal-header'></div>
            <div className='metadata-edit-modal-metadata'>
              <div className='doc-info-header'></div>

              <DocumentInfoHeader clause={clause} />

              <form className='metadata-form' onSubmit={handleSubmit(processFormData)}>
                <ReportedField
                  item={clause}
                  isReportedChecked={isClauseReportedChecked}
                  setIsReportedChecked={setIsClauseReportedChecked}
                  dictionaries={dictionaries}
                  editMode
                />
                <ClauseMetadataForm
                  formMethods={formMethods}
                  disableFromMetadataEdit={true}
                  clause={clause}
                  onUpdateEntities={handleUpdateEntities}
                  inheritedMetadata={inheritedMetadata}
                  inheritedMetadataIsLoading={inheritedMetadataIsLoading}
                />
                <ApiError apiError={apiError} />
                <div className='metadata-form-submit'>
                  {!inheritedMetadataIsLoading && (
                    <LoadingButton
                      data-test-id={TEST_ID.BULK_METADATA.SAVE_BUTTON}
                      loading={isLoading}
                      disabled={saveDisabled}
                      fullWidth
                      variant='contained'
                      size='large'
                      type='submit'
                    >
                      Save
                    </LoadingButton>
                  )}
                </div>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};
