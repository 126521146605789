import { useController } from 'react-hook-form';
import { Control } from 'react-hook-form';
import {
  SearchMethod,
  SEQUENTIAL_SEARCH,
} from '../../../store/files/documentsAndClauses/list.types';

interface UseSelectExactSearchWhenSynonymEnabledProps {
  control: Control;
  prefixed: string;
  index?: number;
}

export const useSelectExactSearchWhenSynonymEnabled = ({
  control,
  prefixed,
  index,
}: UseSelectExactSearchWhenSynonymEnabledProps) => {
  const searchMethodName = `${prefixed}${SEQUENTIAL_SEARCH}.${index}.SearchMethod`;

  const { field: searchMethodField } = useController({
    control,
    name: searchMethodName,
  });

  const setExactSearchOnSynonymEnabled = (checked: boolean) => {
    if (checked) {
      searchMethodField.onChange(SearchMethod.EXACT);
    }
  };

  return { setExactSearchOnSynonymEnabled };
};
