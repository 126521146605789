import React, { useEffect, useState } from 'react';
import './DocumentComponents.scss';
import { DocumentComponentsViewerProps } from './DocumentComponents.types';
import { ComponentProcessingStatus } from '../../../store/files/documents/documents.list.types';
import { useGetComponentsQuery } from '../../../store/files/documents/documents.list.service';
import { DOCUMENT_COMPONENTS_REFRESH_INTERVAL } from '../../../config/config';
import { DocumentComponentsList } from './DocumentComponentsList';
import { DocumentComponentsPending } from './DocumentComponentsPending';
import { useDispatch } from 'react-redux';
import { updateDocumentListAction } from '../DocumentViewModal/useFetchDocumentComponents.hook';
import { changeDocumentProcessingStatusViewModal } from '../../../store/files/documents/documents.slice';

export const DocumentComponentsViewer = ({
  documentId,
  componentProcessingStatus,
}: DocumentComponentsViewerProps) => {
  const [polling, setPolling] = useState(0);
  const dispatch = useDispatch();

  const { currentData: components, error } = useGetComponentsQuery(documentId, {
    pollingInterval: polling,
  });

  const statusPending = componentProcessingStatus === ComponentProcessingStatus.PENDING;
  const componentsAvailable = !!components?.Components.length;

  useEffect(() => {
    if (statusPending && !componentsAvailable && !error) {
      setPolling(DOCUMENT_COMPONENTS_REFRESH_INTERVAL);
    } else {
      setPolling(0);
      if (componentsAvailable && !error) {
        dispatch(updateDocumentListAction(documentId, ComponentProcessingStatus.DONE));
        dispatch(changeDocumentProcessingStatusViewModal(ComponentProcessingStatus.DONE));
      }
    }
  }, [dispatch, documentId, componentsAvailable, error, statusPending]);

  return (
    <div className='document-components-viewer'>
      {componentsAvailable ? (
        <DocumentComponentsList documentId={documentId} components={components} />
      ) : (
        <DocumentComponentsPending pending={statusPending} error={!!error} />
      )}
    </div>
  );
};
