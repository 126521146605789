import {
  ClauseDictionariesResponse,
  MetadataDictionary,
  MetadataDictionaryValue,
} from '../../../store/files/upload/list.service.types';
import {
  ClauseInheritedMetadataResponse,
  ClauseResponse,
} from '../../../store/files/clauses/clauses.list.types';
import { getDictionaryLabelByValue } from '../../../store/files/upload/list.helpers';
import React, { useEffect, useMemo } from 'react';
import { ClauseMetadata, ClauseMetadataFields } from '../../UploadClause/UploadClause.types';
import {
  MenuItemMetadataDictionaryValue,
  MetadataFieldName,
  SELECT_ALL,
  SELECT_ALL_GERMAN_COMPANIES,
  selectableGroups,
} from './ClauseMetadataForm.types';
import { DocumentResponse } from '../../../store/files/documents/documents.list.types';
import { UseFormSetValue } from 'react-hook-form';
import { DocumentMetadataFields } from './MetadataForm.types';
import { getGermanEntitiesSubsetFromFilteredValues } from '../../Documents/DocumentsSearch/DocumentFilter.helpers';

export const getRelatedData = (entities: string[], entityValues: MetadataDictionaryValue[]) => {
  const countries = Array.from(
    new Set(
      entities?.map((entityId: string) => {
        const matchedEntity = entityValues.find(({ value }) => value === entityId);
        return matchedEntity?.related?.Country || '';
      })
    )
  );
  const language =
    entities.length === 1
      ? entityValues.find(({ value }) => value === entities[0])?.related?.Language || ''
      : '';
  return {
    relatedCountries: countries,
    relatedLanguage: language,
  };
};

// takes current metadata values from currently edited clause (from API response), creates set of values from W dictionary,
// metadata which was not found in W dictionary is non selectable item, so function
// returns object containing label, value, 'Non Selectable' group and 'isNonEditable': false flag
export const generateNonSelectableMetadata = (
  clauseResponse: ClauseResponse,
  dictionary: MetadataDictionary,
  type: MetadataFieldName,
  writeAccessValues: MetadataDictionaryValue[]
): MetadataDictionaryValue[] => {
  const values = clauseResponse[type];
  const writeAccessSet = new Set(writeAccessValues.map((item) => item.value));
  const filteredValues = values.filter((value) => !writeAccessSet.has(value));

  return filteredValues.map((value) => {
    const label = getDictionaryLabelByValue(dictionary, value) || 'Dictionary error';

    return {
      label: label,
      value: value,
      group: selectableGroups[1],
      isNonEditable: true,
    };
  });
};

export const useFormDropdownData = (
  selectedLobsValues: string[],
  lopValues: MenuItemMetadataDictionaryValue[],
  lobValues?: MetadataDictionaryValue[],
  businessTypesValues?: MetadataDictionaryValue[],
  clause?: ClauseResponse,
  readAccessDictionaries?: ClauseDictionariesResponse,
  writeAccessDictionaries?: ClauseDictionariesResponse,
  baseDocumentForClauseCreation?: DocumentResponse,
  inheritedMetadata?: ClauseInheritedMetadataResponse
) => {
  return useMemo(() => {
    const fieldMapping: { [key in MetadataFieldName]: keyof DocumentResponse } = {
      Entities: DocumentMetadataFields.Entity,
      Countries: DocumentMetadataFields.Country,
      Lobs: DocumentMetadataFields.Lob,
      Lops: DocumentMetadataFields.Lop,
      BusinessTypes: DocumentMetadataFields.BusinessType,
    };

    const getDropdownData = (type: MetadataFieldName) => {
      // After removing inherited data, add 'Selectable' field to every item of W dictionary
      // Generic for every metadata, thanks to "type" parameter.
      const writeAccessValues: MetadataDictionaryValue[] = (() => {
        if (writeAccessDictionaries?.[type]?.values) {
          const inheritedValue: string[] = inheritedMetadata?.[type] ?? [];
          return writeAccessDictionaries[type].values
            .filter((value) => !inheritedValue.includes(value.value))
            .map((value) => ({
              ...value,
              group: selectableGroups[0],
            }));
        }
        return [];
      })();

      let nonEditableValues: MetadataDictionaryValue[] = [];

      //only for clause edit (only then clause is not undefined)
      if (clause && readAccessDictionaries) {
        nonEditableValues = generateNonSelectableMetadata(
          clause,
          readAccessDictionaries[type],
          ClauseMetadataFields[type],
          writeAccessValues
        );
      }

      //only for semi-auto clause creation (only then baseDocumentForClauseCreation is not undefined)
      if (baseDocumentForClauseCreation) {
        const mappedField = fieldMapping[type]; //changes type to 'singular' form to fit docs keys
        if (mappedField) {
          const baseDocumentValue = baseDocumentForClauseCreation[mappedField]; //picks value of given metadata field from semi-auto base document

          // scanning writeAccessValues array to find the index of the object with value same as in base document
          const indexInWriteAccess = writeAccessValues.findIndex(
            (item) => item.value === baseDocumentValue
          );

          //if that object is found, take it and change the group to 'nonSelectable', and add isNonEditable flag on top od that, to block
          //unselection of items taken from semi-auto baseDoc
          if (indexInWriteAccess > -1) {
            const [baseDocumentItem] = writeAccessValues.splice(indexInWriteAccess, 1);
            const updatedBaseDocumentItem = {
              ...baseDocumentItem,
              group: selectableGroups[1],
              isNonEditable: true,
            };
            //in case semi-auto logic was triggered, 'clause' prop is undefined, so nonEditableValues is empty array. Push the above object into it.
            nonEditableValues.push(updatedBaseDocumentItem);
          }
        }
      }

      return {
        writeAccess: writeAccessValues,
        nonEditable: nonEditableValues,
      };
    };

    const entitiesData = getDropdownData(ClauseMetadataFields.Entities);
    const mergedEntitiesValues = [...entitiesData.writeAccess, ...entitiesData.nonEditable];
    const entitiesGroups = entitiesData.nonEditable.length ? selectableGroups : undefined;
    const nonEditableEntities = entitiesData.nonEditable;

    const countriesData = getDropdownData(ClauseMetadataFields.Countries);
    const mergedCountriesValues = [...countriesData.writeAccess, ...countriesData.nonEditable];
    //no need to create Countries groups, since Countries field is disabled and never split into groups

    const lobsData = getDropdownData(ClauseMetadataFields.Lobs);
    const nonEditableLobsValuesSet = new Set(lobsData.nonEditable.map((item) => item.value));
    const mergedLobValues = lobValues?.map((item) =>
      nonEditableLobsValuesSet.has(item.value)
        ? { ...item, isNonEditable: true, group: selectableGroups[1] }
        : { ...item, group: selectableGroups[0] }
    );
    const lobsGroups = lobsData.nonEditable.length ? selectableGroups : undefined;

    const lopsData = getDropdownData(ClauseMetadataFields.Lops);
    const nonEditableLopsValuesSet = new Set(lopsData.nonEditable.map((item) => item.value));
    const LopsGroups = mapSelectedLabels(writeAccessDictionaries?.Lobs.values, selectedLobsValues);
    const mergedLopValues = lopValues
      .filter((value) => value.group && LopsGroups.includes(value.group))
      .map((item) =>
        nonEditableLopsValuesSet.has(item.value) ? { ...item, isNonEditable: true } : item
      );

    const businessTypesData = getDropdownData(ClauseMetadataFields.BusinessTypes);
    const nonEditableBusinessTypesValuesSet = new Set(
      businessTypesData.nonEditable.map((item) => item.value)
    );
    const mergedBusinessTypesValues = businessTypesValues?.map((item) =>
      nonEditableBusinessTypesValuesSet.has(item.value)
        ? { ...item, isNonEditable: true, group: selectableGroups[1] }
        : { ...item, group: selectableGroups[0] }
    );
    const businessTypesGroups = businessTypesData.nonEditable.length ? selectableGroups : undefined;

    return {
      nonEditableEntities: nonEditableEntities, //necessary to remove from API call for clauseEdit
      mergedEntitiesValues: mergedEntitiesValues,
      entitiesGroups: entitiesGroups,
      mergedCountriesValues: mergedCountriesValues,
      mergedLobValues: mergedLobValues as MetadataDictionaryValue[],
      lobsGroups: lobsGroups,
      mergedLopValues: mergedLopValues,
      lopsGroups: LopsGroups,
      mergedBusinessTypesValues: mergedBusinessTypesValues,
      businessTypesGroups: businessTypesGroups,
    };
  }, [
    lobValues,
    lopValues,
    businessTypesValues,
    writeAccessDictionaries,
    clause,
    readAccessDictionaries,
    baseDocumentForClauseCreation,
    inheritedMetadata,
    selectedLobsValues,
  ]);
};

export const mapSelectedLabels = (
  dictValues: MetadataDictionaryValue[] | undefined,
  selected: string[]
) => {
  if (!dictValues || !selected) return [];
  return dictValues.filter(({ value }) => selected.includes(value)).map(({ label }) => label);
};

export const mapLopValues = (
  dictionaries: ClauseDictionariesResponse | undefined
): MenuItemMetadataDictionaryValue[] => {
  if (!dictionaries) return [];
  const { Lobs, Lops } = dictionaries;
  const mappedGroups: Record<string, string> = Lobs.values.reduce(
    (previousValue, { label, related }) => ({
      ...previousValue,
      ...related?.Lop?.reduce((pv, lop) => ({ ...pv, [lop]: label }), {}),
    }),
    {}
  );

  return Lops.values.map((lop) => ({ ...lop, group: mappedGroups[lop.value] }));
};

export const extractSelectValue = ({
  target: { value },
}: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): string[] => {
  return Array.isArray(value) ? value : [value];
};

export const filterOutUnselected = (
  dictValues: MetadataDictionaryValue[],
  parentVals: string[],
  childVals: string[],
  relatedFn: (dictValues: MetadataDictionaryValue[], parentVals: string[]) => string[]
): string[] => {
  const related = relatedFn(dictValues, parentVals);
  return related.length
    ? childVals.filter((value) => related.includes(value))
    : parentVals.length
    ? childVals
    : [];
};

export const filterOutUnselectedLop = (
  dictValues: MetadataDictionaryValue[],
  parentVals: string[],
  childVals: string[]
): string[] => {
  const allowed = dictValues
    .filter(({ value }) => parentVals.includes(value))
    .reduce<string[]>(
      (previousValue, { related }) => [...previousValue, ...(related?.Lop ?? [])],
      []
    );

  return childVals.filter((value) => allowed.includes(value));
};

export const updateLobs = (
  dictionaries: ClauseDictionariesResponse,
  values: string[],
  Lobs: string[],
  setValue: UseFormSetValue<ClauseMetadata>
) => {
  const filtered = filterOutUnselected(dictionaries?.Entities.values, values, Lobs, getRelatedLobs);
  setValue(ClauseMetadataFields.Lobs, filtered, { shouldValidate: true });
  return filtered;
};

export const updateLops = (
  dictionaries: ClauseDictionariesResponse,
  values: string[],
  Lops: string[],
  setValue: UseFormSetValue<ClauseMetadata>
) => {
  const filtered = filterOutUnselectedLop(dictionaries?.Lobs.values, values, Lops);
  setValue(ClauseMetadataFields.Lops, filtered, { shouldValidate: true });
};

export const updateBusinessTypes = (
  dictionaries: ClauseDictionariesResponse,
  values: string[],
  BusinessTypes: string[],
  setValue: UseFormSetValue<ClauseMetadata>
) => {
  const filtered = filterOutUnselected(
    dictionaries?.Entities.values,
    values,
    BusinessTypes,
    getBusinessTypes
  );
  setValue(ClauseMetadataFields.BusinessTypes, filtered, { shouldValidate: true });
};

const getRelatedBase = (
  dictValues: MetadataDictionaryValue[] = [],
  parentVals: ClauseMetadata[ClauseMetadataFields.Entities] = [],
  mapFn: (value: MetadataDictionaryValue) => string[] | undefined
) => {
  const filtered = dictValues.filter(({ value }) => parentVals.includes(value)).map(mapFn);
  const related = filtered.filter((value): value is string[] => !!value);
  if (related.length !== filtered.length) return [];
  return related.reduce<string[]>((pv, cv) => [...pv, ...cv], []);
};

export const getRelatedLobs = (
  dictValues: MetadataDictionaryValue[] = [],
  parentVals: ClauseMetadata[ClauseMetadataFields.Entities] = []
): string[] => {
  return getRelatedBase(dictValues, parentVals, ({ related }) => related?.Lob);
};

export const getBusinessTypes = (
  dictValues: MetadataDictionaryValue[] = [],
  parentVals: ClauseMetadata[ClauseMetadataFields.Entities] = []
): string[] => {
  return getRelatedBase(dictValues, parentVals, ({ related }) => {
    if (!related?.BusinessType) return undefined;
    return Array.isArray(related?.BusinessType) ? related?.BusinessType : [related?.BusinessType];
  });
};

export const useFormFiltering = (
  dictionaries: ClauseDictionariesResponse | undefined,
  { Entities, Lobs, BusinessTypes }: ClauseMetadata,
  setValue: UseFormSetValue<ClauseMetadata>
) => {
  const [relatedLobs, relatedBusinessTypes] = useMemo(() => {
    return [
      getRelatedLobs(dictionaries?.Entities.values, Entities),
      getBusinessTypes(dictionaries?.Entities.values, Entities),
    ];
  }, [Entities, dictionaries?.Entities.values]);

  const lobValues = useMemo(
    () =>
      relatedLobs.length
        ? dictionaries?.Lobs.values.filter(({ value }) => relatedLobs.includes(value))
        : dictionaries?.Lobs.values,
    [dictionaries?.Lobs.values, relatedLobs]
  );

  const businessTypesValues = useMemo(
    () =>
      relatedBusinessTypes.length
        ? dictionaries?.BusinessTypes.values.filter(({ value }) =>
            relatedBusinessTypes.includes(value)
          )
        : dictionaries?.BusinessTypes.values,
    [dictionaries?.BusinessTypes.values, relatedBusinessTypes]
  );

  useEffect(() => {
    const newLobsSelection = Lobs ? [...Lobs] : [];
    let shouldUpdate = false;

    Entities?.forEach((entity) => {
      const relatedLobsForEntity = getRelatedLobs(dictionaries?.Entities.values, [entity]);
      if (
        relatedLobsForEntity.length === 1 &&
        !newLobsSelection.includes(relatedLobsForEntity[0])
      ) {
        newLobsSelection.push(relatedLobsForEntity[0]);
        shouldUpdate = true;
      }
    });

    if (shouldUpdate) {
      setValue(ClauseMetadataFields.Lobs, newLobsSelection, { shouldValidate: true });
    }
    // Lobs had to be removed from deps. Effect should run ONLY when user change the companies selection.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Entities, dictionaries?.Entities.values, setValue]);

  useEffect(() => {
    if (BusinessTypes?.length === 0 && relatedBusinessTypes.length === 1) {
      setValue(ClauseMetadataFields.BusinessTypes, relatedBusinessTypes, { shouldValidate: true });
    }
  }, [BusinessTypes?.length, relatedBusinessTypes, setValue]);

  return { noEntitySelected: !Entities?.length, lobValues, businessTypesValues };
};

export const handleSpecialSelections = (
  selectedValues: string[],
  setValue: UseFormSetValue<ClauseMetadata>,
  metadataField: MetadataFieldName,
  mergedValues: MetadataDictionaryValue[]
) => {
  if (selectedValues.includes(SELECT_ALL_GERMAN_COMPANIES)) {
    const selectableGermanCompanies = getGermanEntitiesSubsetFromFilteredValues(mergedValues);
    const currentSelectionCombinedWithGerman = [
      ...selectedValues.filter((value) => value !== SELECT_ALL_GERMAN_COMPANIES),
      ...selectableGermanCompanies,
    ];
    const uniqueValues = Array.from(new Set(currentSelectionCombinedWithGerman));
    setValue(metadataField, uniqueValues);
  }
  if (selectedValues.includes(SELECT_ALL)) {
    const finalValues = selectedValues.includes(SELECT_ALL)
      ? mergedValues
        ? mergedValues.filter((v) => v.value !== SELECT_ALL).map((val) => val.value)
        : []
      : selectedValues;

    selectedValues.length - 1 !== mergedValues.length
      ? setValue(metadataField, finalValues)
      : setValue(metadataField, []);
  }
};

export const handleNonEditableDictValues = (
  dictValues: MetadataDictionaryValue[],
  selected: string[] | undefined
) => {
  return selected
    ? dictValues.map((v) => ({ ...v, isNonEditable: selected.includes(v.value) }))
    : dictValues;
};
