import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import ReportStatus from '../Reports/ReportStatus';
import { DocumentContextMenuButton } from '../Documents/DocumentContextMenu/DocumentContextMenuButton';
import { ContextMenuHandler } from '../Documents/DocumentContextMenu/DocumentContextMenu.types';
import {
  COMPONENT_COMPARISON_STATUS,
  ComponentComparison,
} from '../../store/files/documents/documents.list.types';
import { Tooltip } from '@mui/material';
import { FILE_EXTENSIONS } from '../../store/ui/ui.types';

export const getColumns = (
  handleMenuButtonClick: ContextMenuHandler<ComponentComparison>
): GridColDef[] => [
  {
    field: 'Id',
    headerName: 'File name',
    flex: 8,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams) => {
      const value = getComparisonFileName(row);
      return (
        <Tooltip title={value} placement='top-start'>
          <span className='manage-tag-list-synonyms-column'>{value}</span>
        </Tooltip>
      );
    },
  },
  {
    field: 'TaskStatus',
    headerName: 'Status',
    flex: 2,
    sortable: false,
    sortComparator: (v1, v2) => statusSortOrder(v1) - statusSortOrder(v2),
    renderCell: ({ row: { TaskStatus } }: GridRenderCellParams) => {
      switch (TaskStatus) {
        case COMPONENT_COMPARISON_STATUS.PENDING:
          return <ReportStatus mode='pending' label='Pending' />;
        case COMPONENT_COMPARISON_STATUS.DONE:
          return <ReportStatus mode='done' label='Done' />;
        case COMPONENT_COMPARISON_STATUS.ERROR:
          return <ReportStatus mode='failed' label='Failed' />;
      }
    },
  },
  {
    field: 'CreatedBy',
    headerName: 'Created by',
    flex: 6,
    sortable: false,
    renderCell: ({ value }: GridRenderCellParams) => (
      <Tooltip title={value} placement='top-start'>
        <span className='manage-tag-list-synonyms-column'>{value}</span>
      </Tooltip>
    ),
  },
  {
    field: 'action',
    headerName: '',
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <DocumentContextMenuButton
        onClick={(event: React.MouseEvent<HTMLElement>) => handleMenuButtonClick(event, row)}
      />
    ),
  },
];

const statusSortOrder = (status: COMPONENT_COMPARISON_STATUS) =>
  [
    COMPONENT_COMPARISON_STATUS.ERROR,
    COMPONENT_COMPARISON_STATUS.PENDING,
    COMPONENT_COMPARISON_STATUS.DONE,
  ].indexOf(status);

export const isRowSelectable = ({ row: { TaskStatus } }: GridRowParams<ComponentComparison>) =>
  [COMPONENT_COMPARISON_STATUS.DONE, COMPONENT_COMPARISON_STATUS.ERROR].includes(TaskStatus);

export const getComparisonFileName = ({
  BaseDocumentName,
  CompareDocumentNames,
  CreatedAt,
}: ComponentComparison) => {
  const comparedNames = CompareDocumentNames.join(' vs ');
  const createdAt = CreatedAt.slice(0, 16).replaceAll(':', '_');
  return `${BaseDocumentName} vs ${comparedNames} ${createdAt}${FILE_EXTENSIONS.DOCX}`;
};
