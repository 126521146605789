import React, { useState } from 'react';
import { DocumentComponentsListProps } from './DocumentComponents.types';
import { useAppDispatch } from '../../../store/hooks';
import { useSnackbar } from 'notistack';
import { useAdjustComponentsMutation } from '../../../store/files/documents/documents.list.service';
import {
  getMergedComponents,
  getSplitComponents,
  getStatusChangeComponent,
  updateDocumentComponents,
} from './DocumentComponentsList.helpers';
import { ErrorMessages } from '../../../services/errors.service.types';
import { DocumentComponent } from './DocumentComponent';
import { DocumentComponentMerge } from './DocumentComponentMerge';
import { DocumentComponentsLoader } from './DocumentComponentsLoader';
import { DocumentComponentStatus } from '../../../store/files/documents/documents.list.types';

export const DocumentComponentsList = ({
  documentId,
  components: { Components, Timestamp },
}: DocumentComponentsListProps) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [adjust] = useAdjustComponentsMutation();

  const handleConfirm = (componentId: string) => async (offsets: number[]) => {
    setLoading(true);
    try {
      const { adjustActions, newComponents } = getSplitComponents(Components, componentId, offsets);
      const body = { Actions: adjustActions, Timestamp };
      const response = await adjust({ documentId, body }).unwrap();
      dispatch(updateDocumentComponents(documentId, newComponents, response.Timestamp));
    } catch (e) {
      enqueueSnackbar(ErrorMessages.ComponentMergeError, { variant: 'error' });
    }
    setLoading(false);
  };

  const handleClick = (componentId: string) => async () => {
    setLoading(true);
    try {
      const { adjustActions, newComponents } = getMergedComponents(Components, componentId);
      const body = { Actions: adjustActions, Timestamp };
      const response = await adjust({ documentId, body }).unwrap();
      dispatch(updateDocumentComponents(documentId, newComponents, response.Timestamp));
    } catch (e) {
      enqueueSnackbar(ErrorMessages.ComponentMergeError, { variant: 'error' });
    }
    setLoading(false);
  };

  const handleStatusChange =
    (componentId: string) => async (newStatus: DocumentComponentStatus) => {
      setLoading(true);
      try {
        const { adjustActions, newComponents } = getStatusChangeComponent(
          Components,
          componentId,
          newStatus
        );
        const body = { Actions: adjustActions, Timestamp };
        const response = await adjust({ documentId, body }).unwrap();
        dispatch(updateDocumentComponents(documentId, newComponents, response.Timestamp));
      } catch (e) {
        enqueueSnackbar(ErrorMessages.ComponentStatusChangeError, { variant: 'error' });
      }
      setLoading(false);
    };

  return (
    <div className='document-components-list'>
      {Components.map((component) => (
        <React.Fragment key={component.Id}>
          <DocumentComponent
            component={component}
            onConfirm={handleConfirm(component.Id)}
            onStatusChange={handleStatusChange(component.Id)}
          />
          <DocumentComponentMerge onClick={handleClick(component.Id)} />
        </React.Fragment>
      ))}

      {loading && <DocumentComponentsLoader />}
    </div>
  );
};
