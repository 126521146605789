import React, { useMemo } from 'react';
import { MAX_WORDING_LENGTH } from '../../config/config';
import { CustomTextField } from '../UploadFiles/MetadataForm/MetadataFormHelpers';
import { FieldProps } from './UploadClause.types';
import SpecialCharacterTextEditor from '../UploadFiles/MetadataEditModal/SpecialCharacterTextEditor';

const useCustomTextFieldRenderer = (shouldPrefillForm: boolean) => {
  return useMemo(() => {
    if (shouldPrefillForm) {
      //for "Semi-auto clause extract" from document and "adjust clause" (for GPC users only)
      return ({ field }: FieldProps) => (
        <SpecialCharacterTextEditor value={field.value} onChange={field.onChange} />
      );
    } else {
      //Regular clause upload
      return ({ field, fieldState }: FieldProps) => (
        <CustomTextField
          inputProps={{ maxLength: MAX_WORDING_LENGTH }}
          {...field}
          multiline
          helperWarning={fieldState?.error}
          label=''
          hasCounter
          InputLabelProps={{ shrink: true }}
          placeholder='Input text here'
        />
      );
    }
  }, [shouldPrefillForm]);
};

export default useCustomTextFieldRenderer;
