import { ButtonWithIcon } from '../../../StaticComponents/ButtonWithIcon';
import {
  ComponentProcessingStatus,
  DocumentResponse,
} from '../../../../store/files/documents/documents.list.types';
import React, { useState } from 'react';
import { ReferenceDocumentSelectionModal } from './ReferenceDocumentSelectionModal';
import { ConfirmationAgreementModal } from '../../../StaticComponents/Modals/ConfirmationModal/ConfirmationAgreementModal';
import { AIComparisonTermsAndConditions } from './AIComparisonTermsAndConditions';
import { AIComparisonPendingModal } from './AIComparisonPendingModal';
import { useCreateComponentComparisonMutation } from '../../../../store/files/documents/documents.list.service';
import { ErrorMessages } from '../../../../services/errors.service.types';
import { useSnackbar } from 'notistack';

export const AIComparisonFlow: React.FC<{ comparedDocs: DocumentResponse[] }> = ({
  comparedDocs,
}) => {
  const [step, setStep] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [createComparison] = useCreateComponentComparisonMutation();
  const { enqueueSnackbar } = useSnackbar();

  const showAnalyzeButton =
    comparedDocs.length === 2 &&
    comparedDocs.some((doc) => doc.ComponentProcessingStatus === ComponentProcessingStatus.DONE);

  const handleClose = () => {
    setStep(0);
    setSelectedDocument(null);
  };

  const handleSelectionConfirm = (document: string) => {
    setSelectedDocument(document);
    setStep(2);
  };

  const handleTermsConfirm = async () => {
    if (!selectedDocument) return;

    const baseDoc = comparedDocs.find((doc) => doc.FileName === selectedDocument);
    if (!baseDoc) return;

    const compareDoc = comparedDocs.find((doc) => doc.FileName !== selectedDocument);
    if (!compareDoc) return;

    setIsLoading(true);
    try {
      await createComparison({
        BaseDocumentId: baseDoc.DocumentId,
        CompareDocumentIds: [compareDoc.DocumentId],
      }).unwrap();

      setStep(3);
    } catch (error) {
      enqueueSnackbar(ErrorMessages.ComponentComparisonError, { variant: 'error' });
      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {showAnalyzeButton && (
        <ButtonWithIcon className='document-compare-analyse-button red' onClick={() => setStep(1)}>
          Analyse
        </ButtonWithIcon>
      )}

      <ReferenceDocumentSelectionModal
        open={step === 1}
        onClose={handleClose}
        onConfirm={handleSelectionConfirm}
        comparedDocs={comparedDocs}
      />

      <ConfirmationAgreementModal
        className='chat-agreement-modal'
        open={step === 2}
        onClose={handleClose}
        onConfirm={handleTermsConfirm}
        message={AIComparisonTermsAndConditions}
        label='I agree to the terms and conditions'
        successButtonLabel='Confirm'
        title='GWR AI Assistant'
        cancelButtonLabel='Cancel'
        loading={isLoading}
      />

      <AIComparisonPendingModal open={step === 3} onClose={handleClose} />
    </>
  );
};
