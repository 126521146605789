import { AppBar, Badge, Toolbar } from '@mui/material';
import logoImg from '../../assets/icons/ergo_logo.svg';
import './Header.scss';
import React from 'react';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { FormatAlignLeft } from '@mui/icons-material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import { TRAININGS_URL } from '../../config/config';
import { RoutePath } from '../../services/route.service';
import { NavLink, useNavigate } from 'react-router-dom';
import { MarsMenu } from '../StaticComponents/MarsMenu/MarsMenu';
import { UserProfile } from './UserProfile';
import { useUserAccessContext } from '../../contexts/UserAccess';
import { useAppDispatch } from '../../store/hooks';
import { showUploadFilesPopup } from '../../store/ui/ui.slice';
import { UploadFilesPopup } from '../UploadFiles/UploadFilesPopup';
import { TEST_ID } from '../../config/test-fields-ids.config';
import { UploadButtonWithDraftMode } from '../StaticComponents/UploadButton/UploadButtonWithDraftMode';

export const Header = () => {
  const navigate = useNavigate();
  const { hasReadAccess, hasWriteAccess, hasSandboxAccess } = useUserAccessContext();

  const dispatch = useAppDispatch();

  const onClick = () =>
    hasWriteAccess || hasSandboxAccess
      ? dispatch(showUploadFilesPopup())
      : navigate(RoutePath.NO_WRITE_ACCESS);

  return (
    <AppBar className='app-bar' position='relative'>
      <Toolbar>
        <img src={logoImg} className='logo' alt='logo' />

        {hasReadAccess && (
          <>
            <NavLink
              data-test-id={TEST_ID.DOCUMENTS_PAGE.DOCUMENTS_PAGE_BUTTON_LINK}
              className='nav-link'
              to={RoutePath.DOCUMENTS}
            >
              <AssignmentTurnedInOutlinedIcon />
              Documents
            </NavLink>

            <NavLink
              data-test-id={TEST_ID.CLAUSES_PAGE.CLAUSES_PAGE_BUTTON_LINK}
              className='nav-link'
              to={RoutePath.CLAUSES}
            >
              <FormatAlignLeft />
              Clauses
            </NavLink>

            <NavLink
              data-test-id={TEST_ID.BOOKMARKS_PAGE.BOOKMARKS_PAGE_BUTTON_LINK}
              className='nav-link'
              to={RoutePath.BOOKMARKS}
            >
              <BookmarkBorderIcon />
              Bookmarks
            </NavLink>

            <MarsMenu
              icon={<DescriptionOutlinedIcon />}
              name='My files'
              menuItems={[
                {
                  label: 'Uploaded documents',
                  href: RoutePath.UPLOADED_DOCUMENTS,
                },
                {
                  label: 'Uploaded clauses',
                  href: RoutePath.UPLOADED_CLAUSES,
                },
                {
                  label: 'Reported documents',
                  href: RoutePath.REPORTED_DOCUMENTS,
                },
                {
                  label: 'Reported clauses',
                  href: RoutePath.REPORTED_CLAUSES,
                },
              ]}
              key='My files'
            />

            <NavLink
              data-test-id={TEST_ID.REPORTS_PAGE.REPORTS_PAGE_BUTTON_LINK}
              className='nav-link'
              to={RoutePath.REPORTS}
            >
              <AssessmentOutlinedIcon />
              Reports
            </NavLink>

            {hasSandboxAccess && (
              <MarsMenu
                data-test-id={TEST_ID.SANDBOX_PAGE.SANDBOX_PAGE_BUTTON_LINK}
                icon={<AppRegistrationOutlinedIcon />}
                name='Sandbox'
                menuItems={[
                  {
                    label: 'Draft documents',
                    href: RoutePath.SANDBOX_DOCUMENTS,
                  },
                  {
                    label: 'Draft clauses',
                    href: RoutePath.SANDBOX_CLAUSES,
                  },
                  {
                    label: 'Wording analysis',
                    href: RoutePath.SANBOX_ANALYSIS,
                  },
                ]}
                key='Sandbox'
              />
            )}
          </>
        )}

        <span className='spacer'></span>

        {hasReadAccess && <UploadButtonWithDraftMode onClick={onClick} />}

        <IconButton href={TRAININGS_URL} target='_blank'>
          <HelpOutlineIcon />
        </IconButton>

        {hasReadAccess && (
          <Badge className='top-notification'>
            <NotificationsNoneOutlinedIcon color='disabled' />
          </Badge>
        )}

        <UserProfile />

        <UploadFilesPopup />
      </Toolbar>
    </AppBar>
  );
};
